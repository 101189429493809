import { css } from '@linaria/core';
import { StyledSpinner } from '../Spinner';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';

export const videoJsSpinnerClass = css`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
`;

export const StyledVideoJsSpinner = extendWithClassNames(StyledSpinner)(videoJsSpinnerClass);
