import { AutoTopicWithFallbackWithTheme } from '../components/CoCo/AutoTopic';
import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { useParams } from '../dwouter';
import { generatePaginatedPath } from '../components/Pagination.jsx';

export const AutoTopicPage = () => {
  const { contentId, langCode, pageNumber } = useParams();
  const dataUrl = `${langCode}/content/auto-topic/${contentId}`;
  const queryDef = {
    path: `${pageNumber ? generatePaginatedPath({ url: dataUrl, pageNum: pageNumber }) : dataUrl}`,
    depts: [langCode, contentId, pageNumber],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <AutoTopicWithFallbackWithTheme autoTopic={content} />
      )}
    </WithGraphQLContentHandling>
  );
};
