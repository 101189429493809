import { InView } from 'react-intersection-observer';
import { cx } from '@linaria/core';

import { isValidList } from '../../utils/contentUtils';
import { noop, toKebabCase } from '../../utils/commons';
import { useTopOffsetHeaderThreshold } from '../hooks/useTopOffsetHeaderThreshold';
import { StyledCoCoAd as CoCoAd } from './CoCoAd.jsx';
import { isTopCoCoCo } from './coCoCoUtils';
import { isProgramInfoSpace } from '../zones/zoneUtils';

import { ContentPageLayout } from '../layouts/ContentPageLayout.jsx';
import { darkCoCoComponentBackgroundSelectorStyles } from './CoCoComponentBackgroundSelector.jsx';
import { useHeaderColorToggle } from '../hooks/useHeaderColorToggle';

const getCollectionName = cocoCmpConfig => (cocoCmpConfig.includesMultipleConfigs ? 'cocoContents' : 'contents');

export const getCoCoSectionId = compositionCmp => [toKebabCase(compositionCmp.type), compositionCmp.id].filter(v => !!v).join('-');

export const SingleCoCoComponentSelector = ({ cocoConf, compositionComp }) => {
  const contents = compositionComp[getCollectionName(cocoConf)];
  const { cocoCmp: ContentBlockTag, extraProps = {} } = cocoConf;

  return (
    <ContentBlockTag
      contents={contents}
      configuration={compositionComp.configuration}
      className={toKebabCase(compositionComp.type)}
      pageSectionId={getCoCoSectionId(compositionComp)}
      {...extraProps}/>
  );
};

const hasCoCoContentsPredicate = cocoCmpTypes => compositionComp => {
  const cocoConf = cocoCmpTypes[compositionComp.type];
  const contents = compositionComp[getCollectionName(cocoConf)];
  return isValidList(contents);
};

// eslint-disable-next-line fp/no-mutating-methods
export const getCoCoCosWithContents = cocoCmpTypes => infoSpace => Object.values(cocoCmpTypes)
  .flatMap(cocoCmpConfig => infoSpace[cocoCmpConfig.type.toLowerCase()])
  .filter(hasCoCoContentsPredicate(cocoCmpTypes))
  .sort((c1, c2) => c1.order - c2.order);

const darkCoCoComponentClassName = cx(
  ContentPageLayout.darkStyles,
  darkCoCoComponentBackgroundSelectorStyles,
);

export const CoCoComponentSelector = ({
  coCoContentBlockConfig, infoSpace, isFirst = false, bottomElementFn = noop,
}) => {
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();
  const compositionComponents = getCoCoCosWithContents(coCoContentBlockConfig)(infoSpace);
  const bottomElement = bottomElementFn();
  const { onChangeInViewListenerForHeaderSwitch } = useHeaderColorToggle();

  return (
    compositionComponents.map((compositionComp, index) => {
      const isTopCoCo = isTopCoCoCo(compositionComp);
      const cocoConf = coCoContentBlockConfig[compositionComp.type];
      const Container = (isFirst && index === 0) ? {
        Tag: InView,
        props: {
          rootMargin: topOffsetHeaderThreshold,
          onChange: onChangeInViewListenerForHeaderSwitch,
          as: isTopCoCo ? 'span' : 'div',
        },
      } : {
        Tag: isTopCoCo ? 'span' : 'div',
        props: {},
      };

      return (
        <Container.Tag key={compositionComp.id}
          className={cx(
            isTopCoCo
              ? 'basic-color'
              : 'auto-bg-color',
            isProgramInfoSpace(infoSpace) && darkCoCoComponentClassName,
          )}
          {...Container.props}
        >
          <SingleCoCoComponentSelector
            cocoConf={cocoConf}
            compositionComp={compositionComp}
          />
          { (index === compositionComponents.length - 1) && (
            <>
              {bottomElement}
              <CoCoAd infoSpace={infoSpace} compositionComponents={compositionComponents}/>
            </>
          )}
        </Container.Tag>
      );
    })
  );
};
