import { memo } from 'react';
import { cx } from '@linaria/core';

import { useParams } from '../dwouter';
import { HeaderWithTheme } from '../components/Header';
import { ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer } from '../components/Footer';
import { StyledQuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { TopStoryZone } from '../components/zones/TopStoryZone/index.jsx';
import { StyledLivestreamDetails as LivestreamDetails } from '../components/LivestreamDetails.jsx';
import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { ContentDetailActionsWithTheme } from '../components/ContentDetailActions';
import { PageWithData } from './PageWithData.jsx';
import { PageHeaderMetadata } from '../components/PageHeaderMetadata.jsx';
import { ImageSharingMetadata } from '../components/ImageSharingMetadata.jsx';
import { StyledAllProgramsTeaser as AllProgramsTeaser } from '../components/ContentBlocks/AllProgramsTeaser';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { darkCoCoComponentBackgroundSelectorStyles } from '../components/CoCo/CoCoComponentBackgroundSelector.jsx';

const TopStoryZoneMemo = memo(TopStoryZone);

export const LivestreamPageNoQuery = ({
  livestreamChannels,
  today,
  className,
  programsOverview,
}) => {
  const [channel] = livestreamChannels;

  return (
    <OnDetailPageContext>
      <StyledQuickNavigationList content={channel} />
      <PageHeaderMetadata content={channel} />
      <ImageSharingMetadata content={channel} />
      <HeaderWithTheme content={channel} >
        {({ isHeaderInAlternateState }) => (isHeaderInAlternateState ? (
          <ContentDetailActionsWithTheme
            content={channel}
            isInHeader
          />
        ) : null
        )}
      </HeaderWithTheme>
      <div className={className}>
        <BrowserOnlyBreakingBanner />
        <ContentPageLayout
          DetailZoneFn={
            () => (
              <LivestreamDetails
                channel={channel}
                today={today}
              />
            )
          }
          AlternatingColorZoneConfig={{
            compFn: () => (
              <>
                <AllProgramsTeaser
                  className={cx(ContentPageLayout.darkStyles, darkCoCoComponentBackgroundSelectorStyles)}
                  programsOverview={programsOverview}
                />
                <TopStoryZoneMemo/>
              </>
            ),
          }}
        />
      </div>
      <Footer content={channel} />
    </OnDetailPageContext>
  );
};

export const LivestreamPageNoQueryWithTheme = withTheme(LivestreamPageNoQuery)(ContentPageLayout.darkStyles);

export const LivestreamPage = () => {
  const { langCode, channel } = useParams();

  const queryDef = {
    path: `${langCode}/livestream/${channel}`,
    depts: [langCode, channel],
  };

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={(data => ({
      ...data.livestreamChannels[0],
      language: data.topStoriesNavigation.language,
      isRtl: data.topStoriesNavigation.isRtl,
    }))}>
      {data => (
        <LivestreamPageNoQueryWithTheme livestreamChannels={data.livestreamChannels}
          today={data.today}
          programsOverview={data.programsOverview}
        />
      )}
    </PageWithData>
  );
};
