import { StyledFlyingFocus as FlyingFocus } from '../FlyingFocus';
import { BodyScrollContext } from '../navigation/BodyScrollContext.jsx';
import { WindowInnerWidthContextProvider } from '../WindowInnerWidthContext';
import { MobileAppContextProvider } from '../hooks/mobileAppContext';
import { useGptEffect } from '../../utils/gpt';
import { SilentRedirectContextProvider } from '../hooks/silentRedirectContext.jsx';
import { GlobalComponents } from './GlobalComponents';
import { LimitedPageLayoutWithTheme } from './LimitedPageLayout.jsx';
import { ThemeProvider } from '../hooks/themeContext';

export const HooksDependentOnMobileAppContext = () => {
  useGptEffect();
  return null;
};

export const Page = ({ children }) => (
  <>
    <ThemeProvider>
      <GlobalComponents/>
      <SilentRedirectContextProvider>
        <MobileAppContextProvider>
          <BodyScrollContext>
            <WindowInnerWidthContextProvider>
              <LimitedPageLayoutWithTheme>
                {children}
              </LimitedPageLayoutWithTheme>
            </WindowInnerWidthContextProvider>
          </BodyScrollContext>
          <HooksDependentOnMobileAppContext/>
        </MobileAppContextProvider>
      </SilentRedirectContextProvider>
      <FlyingFocus />
    </ThemeProvider>
  </>
);
