import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { PageHeadline } from '../../PageHeadline.jsx';
import { CoCoComponentSelector, getCoCoCosWithContents } from '../CoCoComponentSelector.jsx';
import { coCoComponentFragment } from '../coCoComponentSelectorFragment.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../../a11y/A11yLinkTargetMarker.jsx';
import { TopicHeader, topicHeaderFragment } from '../../ContentBlocks/TopicHeader';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';
import { ids } from '../../../utils/staticElementIds';
import { noop } from '../../../utils/commons';
import { allItemsLinkFragment, StyledAllItemsLink as AllItemsLink } from './AllItemsLink.jsx';
import { StyledCoCoComponentBackgroundSelector } from '../CoCoComponentBackgroundSelector.jsx';
import { darkCoCoComponentBackgroundSelectorWithThemeStyles } from '../CoCoComponentBackgroundSelectorWithTheme.jsx';
import { defaultNewsContentBlockConfig } from '../coCoContentBlockConfig';

export const autoTopicCoCoFragment = makeMemoFragment({
  name: 'AutoTopicCoCo',
  fragment() {
    return gql`fragment ${this.name} on AutoTopic {
      id
      pageHeadline
      isCurrent
      contentComposition {
        informationSpaces {
          id
          ...${coCoComponentFragment.name}
        }
      }
      ...${topicHeaderFragment.name}
      ...${allItemsLinkFragment.name}
    }
    ${coCoComponentFragment.fragment()}
    ${topicHeaderFragment.fragment()}
    ${allItemsLinkFragment.fragment()}
    `;
  },
});

const autoTopicContentBlockConfig = defaultNewsContentBlockConfig;
const getAtsCoCoCosWithContents = getCoCoCosWithContents(autoTopicContentBlockConfig);

export const AutoTopicCoCo = ({ autoTopic, className }) => {
  const {
    pageHeadline,
    contentComposition: { informationSpaces },
    isCurrent,
  } = autoTopic;
  const validInfoSpaces = informationSpaces.filter(infoSpace => isValidList(getAtsCoCoCosWithContents(infoSpace)));
  return (
    <A11yLinkTargetMarker className={className} id={ids.quickNav.mainContent}>
      {isCurrent
        ? <PageHeadline>{pageHeadline}</PageHeadline>
        : <TopicHeader content={autoTopic} />
      }
      <StyledCoCoComponentBackgroundSelector>
        {validInfoSpaces.map((infoSpace, index) => (
          <CoCoComponentSelector key={infoSpace.id}
            coCoContentBlockConfig={autoTopicContentBlockConfig}
            infoSpace={infoSpace}
            isFirst={index === 0}
            bottomElementFn={(index === validInfoSpaces.length - 1)
              ? () => <AllItemsLink content={autoTopic} />
              : noop}
          />
        ))}
      </StyledCoCoComponentBackgroundSelector>
    </A11yLinkTargetMarker>
  );
};

const darkAutoTopicCoCoStyles = cx(
  darkCoCoComponentBackgroundSelectorWithThemeStyles,
  TopicHeader.darkStyles,
  PageHeadline.darkStyles,
);

AutoTopicCoCo.darkStyles = darkAutoTopicCoCoStyles;
