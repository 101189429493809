import { StyledVideoJsSpinner as VideoJsSpinner } from './VideoJsSpinner.jsx';
import { StyledInfoBar as InfoBar } from '../../utils/video/components/InfoBar.jsx';
import { VideoPlayButton } from '../../utils/video/components/VideoPlayButton.jsx';
import { BrowserOnly } from '../BrowserOnly.jsx';

/*
  This component should be wrapped in BrowserOnly component - cannot be rendered on server because skeleton in not rendered
  e.g. on safari and server cannot guess that, while hydration needs to match properly.
*/

export const VideoJsSkeleton = ({
  content,
  showSpinner,
  showSkeleton,
  playButtonTitle,
  onPlayClick,
}) => (
  <BrowserOnly>
    {showSpinner && <VideoJsSpinner />}
    {showSkeleton && (
      <div dir="ltr">
        <VideoPlayButton buttonTitle={playButtonTitle} onClick={onPlayClick}/>
        <InfoBar videoDuration={content.formattedDuration}/>
      </div>
    )}

  </BrowserOnly>
);
