import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';

import { colors, mediaMin } from '../../utils/css';

import { PersonDetailHeader, personDetailHeaderFragment } from './PersonDetailHeader.jsx';
import { StyledContentMoveUpLayout as ContentMoveUpLayout, contentMoveUpLayoutFragment, darkContentMoveUpLayoutStyles } from '../layouts/ContentMoveUpLayout';
import { StyledPersonDetailRichText as PersonDetailRichText, darkPersonDetailRichTextStyles, personDetailRichTextFragment } from './PersonDetailRichText.jsx';
import { makeMemoFragment } from '../../utils/graphql';

export const personDetailsFragment = makeMemoFragment({
  name: 'PersonDetails',
  fragment() {
    return gql`fragment ${this.name} on Person {
      ...${contentMoveUpLayoutFragment.name}
      ...${personDetailHeaderFragment.name}
      ...${personDetailRichTextFragment.name}
    }
    ${contentMoveUpLayoutFragment.fragment()}
    ${personDetailHeaderFragment.fragment()}
    ${personDetailRichTextFragment.fragment()}
    `;
  },
});

export const PersonDetails = ({ person, className }) => (
  <ContentMoveUpLayout
    content={person}
    className={cx(personDetailsClass, className)}
  >
    <PersonDetailHeader person={person}/>
    <PersonDetailRichText person={person}/>
  </ContentMoveUpLayout>
);

export const cssVariables = {
  bgColor: '--person-details-background-color',
};

export const darkPersonDetailsStyles = cx(
  darkContentMoveUpLayoutStyles,
  PersonDetailHeader.darkStyles,
  darkPersonDetailRichTextStyles,
  css`${cssVariables.bgColor}: ${colors.DARK_BLUE_GREY_02};`,
);

export const personDetailsClass = css`
 .content-area {
    background-color: var(${cssVariables.bgColor}, ${colors.BLUE_GREY_01});
    padding-bottom: 30px;
  }

  ${mediaMin.lg`
    .content-area {
      min-height: 20rem;
    }
  `}
`;
