import { css, cx } from '@linaria/core';
import { LinkBase } from '../Links/LinkBase';
import { ButtonTertiarySmall } from '../buttons/Button';
import { useLocation, useParams } from '../../dwouter';
import { getLangCodeFromUrlPath } from '../../utils/url';
import { useHeaderStateContext } from './HeaderStateContext';
import { useThemeContext } from '../hooks/themeContext';

// @VisibleForTesting
const createAlternateUrl = ({ alternateUrls, pageNumber, langCode }) => {
  const alternateUrl = alternateUrls.find(url => getLangCodeFromUrlPath(url) !== langCode);
  return `${alternateUrl}${pageNumber ? `/page-${pageNumber}` : ''}`;
};

export const ChineseToggleButton = ({ alternateUrls, className }) => {
  const [{ pathname }] = useLocation();
  const { pageNumber } = useParams();
  const langCode = getLangCodeFromUrlPath(pathname);
  const alternateUrl = createAlternateUrl({ alternateUrls, pageNumber, langCode });
  const { isHeaderInAlternateState } = useHeaderStateContext();
  const { isDarkTheme } = useThemeContext();

  return (
    <ButtonTertiarySmall
      isA={LinkBase}
      className={cx(
        className,
        chineseToggleButtonClass,
        isDarkTheme && isHeaderInAlternateState && ButtonTertiarySmall.darkStyles,
      )}
      to={alternateUrl}
      title={langCode === 'zh' ? '點擊此處訪問繁體中文頁面' : '点击此处访问简体中文页面'}
    >
      {langCode === 'zh' ? '繁' : '简'}
    </ButtonTertiarySmall>
  );
};

export const chineseToggleButtonClass = css`
  aspect-ratio: 1;
`;
