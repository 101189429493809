import { InView } from 'react-intersection-observer';

import { contentDetailActionsFragment, StyledContentDetailActions as ContentDetailActions } from './ContentDetailActions';
import { useMobileAppContext } from './hooks/mobileAppContext';
import { useTopOffsetHeaderThreshold } from './hooks/useTopOffsetHeaderThreshold';
import { useHeaderColorToggle } from './hooks/useHeaderColorToggle';

export const observedContentDetailActionsFragment = contentDetailActionsFragment;

export const darkObservedContentDetailActionStyles = ContentDetailActions.darkStyles;

export const ObservedContentDetailActions = ({
  content, className,
}) => {
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold(content);
  const { isMobileMode } = useMobileAppContext();
  const { onChangeInViewListenerForHeaderSwitch } = useHeaderColorToggle();

  if (isMobileMode) {
    return null;
  }

  return (
    <InView onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
      {({ ref }) => (
        <ContentDetailActions innerRef={ref} content={content} className={className} />
      )}
    </InView>
  );
};
