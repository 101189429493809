import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import classnames from 'classnames';

import gql from 'graphql-tag';
import { colors } from '../../../utils/css';
import { StyledContentBlock as ContentBlock, darkContentBlockStyles } from '../ContentBlock';
import { StyledTimeSlotRow as TimeSlotRow, timeSlotRowFragment } from './TimeSlotRow.jsx';
import { livestreamScheduleCaptionTodayFragment, StyledLivestreamScheduleCaption as LivestreamScheduleCaption } from './LivestreamScheduleCaption.jsx';
import { StyledLivestreamScheduleHeader as LivestreamScheduleHeader } from './LivestreamScheduleHeader.jsx';
import { useTimeSlots } from '../../RunningLivestreamContext.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { ids } from '../../../utils/staticElementIds';
import { isValidList } from '../../../utils/contentUtils';

export const livestreamScheduleFragment = makeMemoFragment({
  name: 'LivestreamSchedule',
  fragment() {
    return gql`fragment ${this.name} on Channel {
      nextTimeSlots {
        id
        ...${timeSlotRowFragment.name}
      }
    }
    ${timeSlotRowFragment.fragment()}
    `;
  },
});

export const livestreamScheduleTodayFragment = livestreamScheduleCaptionTodayFragment;

export const LivestreamSchedule = ({ className }) => {
  const displayedSlots = useTimeSlots();

  if (!isValidList(displayedSlots)) {
    return null;
  }

  return (
    <ContentBlock
      className={cx(className, darkContentBlockStyles)}
      headlineTranslation="content_block_detail.live_video_program_schedule.headline"
      headlineIsA="h2"
      pageSectionId="live-video-program-schedule"
    >
      <div role="table" className="table" aria-labelledby={ids.livestreamScheduleAriaCaption}>
        <LivestreamScheduleCaption className="caption" id={ids.livestreamScheduleAriaCaption}/>
        <LivestreamScheduleHeader />
        <div role="rowgroup">
          {displayedSlots.map((slot, index) => {
            const slotStartDate = new Date(slot.startDate);
            const nextSlotStartDate = new Date(displayedSlots[index + 1]?.startDate);

            const isLastSlotForTheDay = index < displayedSlots.length - 1 && slotStartDate.getDay() !== nextSlotStartDate.getDay();
            return (
              <TimeSlotRow
                key={slot.id}
                className={classnames({ 'last-slot': isLastSlotForTheDay })}
                timeSlot={slot}
              />
            );
          })}
        </div>
      </div>
    </ContentBlock>
  );
};

export const StyledLivestreamSchedule = styled(LivestreamSchedule)`
  background-color: ${colors.DARK_BLUE_GREY_01};

  .table {
    .caption {
      padding-top: 0;
      padding-bottom: 5px;
    }

    .last-slot {
      border-bottom: 2px solid ${colors.BLUE_GREY_04};
      margin-bottom: 0;
    }
  }

  .content-block h2 {
    overflow: hidden;
  }
`;
