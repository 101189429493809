import gql from 'graphql-tag';

import { cx } from '@linaria/core';
import { NotFoundPage } from '../../../pages/NotFoundPage.jsx';
import { headerFragmentFactory, HeaderWithTheme } from '../../Header';
import { footerFragmentFactory, StyledFooter as Footer } from '../../Footer';
import { navigationCoCoFragment, NavigationCoCo } from './NavigationCoCo.jsx';
import { StyledQuickNavigationList as QuickNavigationList } from '../../a11y/QuickNavigationList.jsx';
import { PremiumGptSlot } from '../../GptSlot';
import { overviewPremiumSlotConfig } from '../../GptSlot/slotConfigs';

import { pageHeaderMetadataFragment, PageHeaderMetadata } from '../../PageHeaderMetadata.jsx';
import { languageNotificationFragment, LanguageNotification } from '../../LanguageNotification.jsx';
import { isHomePage } from '../../../utils/contentUtils';
import { coCoPageHeadlineInHeaderFragment, CoCoPageHeadlineInHeaderWithTheme } from '../CoCoPageHeadlineInHeader.jsx';
import { BrowserOnlyBreakingBanner } from '../../BreakingBanner';
import { useSetPreferredLanguageEffect } from '../../hooks/useSetPreferredLanguageEffect';
import { makeMemoFragment } from '../../../utils/graphql';
import { withTheme } from '../../higherOrderComponents/withTheme.jsx';

export const navigationFragment = makeMemoFragment({
  name: 'NavigationWithFallback',
  fragment() {
    const headerFragment = headerFragmentFactory();
    const footerFragment = footerFragmentFactory();
    return gql`fragment ${this.name} on Navigation {
        name
        visibleInWebApp
        pageTitle
        metaDescription
        ...${pageHeaderMetadataFragment.name}
        ...${coCoPageHeadlineInHeaderFragment.name}
        ...${navigationCoCoFragment.name}
        ...${headerFragment.name}
        ...${footerFragment.name}
        ...${languageNotificationFragment.name}
      }
      ${pageHeaderMetadataFragment.fragment()}
      ${coCoPageHeadlineInHeaderFragment.fragment()}
      ${navigationCoCoFragment.fragment()}
      ${headerFragment.fragment()}
      ${footerFragment.fragment()}
      ${languageNotificationFragment.fragment()}
     `;
  },
});

export const NavigationWithFallback = ({ navigation, className }) => {
  useSetPreferredLanguageEffect(navigation);

  if (!navigation.visibleInWebApp || !navigation.contentComposition) {
    return (
      <NotFoundPage/>
    );
  }
  return (
    <>
      <PageHeaderMetadata content={navigation}/>
      <QuickNavigationList content={navigation}/>
      <HeaderWithTheme
        onlyDefaultState={isHomePage(navigation)}
        content={navigation}
      >
        {({ isHeaderInAlternateState }) => (isHeaderInAlternateState
          ? (
            <CoCoPageHeadlineInHeaderWithTheme navigation={navigation}/>
          )
          : null
        )}
      </HeaderWithTheme>
      <div className={className}>
        <BrowserOnlyBreakingBanner/>
        <LanguageNotification navigation={navigation}/>
        <PremiumGptSlot slotConfig={overviewPremiumSlotConfig}/>
        <NavigationCoCo navigation={navigation} />
      </div>
      <Footer content={navigation}/>
    </>
  );
};

NavigationWithFallback.darkStyles = cx(
  LanguageNotification.darkStyles,
  PremiumGptSlot.darkStyles,
  NavigationCoCo.darkStyles,
);

export const NavigationWithFallbackWithTheme = withTheme(NavigationWithFallback)(NavigationWithFallback.darkStyles);
