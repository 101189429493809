import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@linaria/core';

import { INTERSECTION_OBSERVER_SETTINGS } from '../../LazyLoadDwPicture.jsx';
import { CmpPreviewCompatiblePlaceholder } from '../../CmpPreviewCompatiblePlaceholder.jsx';
import { useFrontendConfig } from '../../FrontendConfigContext.jsx';
import globals from '../../../utils/globals';
import { commonBlockSpacing } from '../../ContentBlocks/ContentBlock/ContentBlock.jsx';

export const taboolaWidgetStyles = css`
  max-width: 100vw;
`;

// TODO: do we want to also load the taboola script only when first widget close to the viewport?
// Taboola currently takes massive amount of space, so loading that script too late might make the page jump
// and I mean here - jump a lot :D Taboola is in test phase now and hidden via feature flag,
// so I hope it will be fine for now
export const TaboolaWidget = ({ containerId }) => {
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);
  const { taboolaPathFragment } = useFrontendConfig();

  useEffect(() => {
    if (inView && taboolaPathFragment) {
      // eslint-disable-next-line no-underscore-dangle, fp/no-mutation
      globals.window._taboola = globals.window._taboola || [];

      // eslint-disable-next-line no-underscore-dangle, fp/no-mutating-methods
      globals.window._taboola.push({
        mode: 'thumbnails-a',
        container: containerId,
        placement: 'Below Article Thumbnails',
        target_type: 'mix',
      });
      // eslint-disable-next-line no-underscore-dangle, fp/no-mutating-methods
      globals.window._taboola.push({ flush: true });
    }
  }, [containerId, inView, taboolaPathFragment]);

  return (
    <CmpPreviewCompatiblePlaceholder className={taboolaWidgetStyles} forwardRef={inViewRef}>
      <div className={commonBlockSpacing} id={containerId}></div>
    </CmpPreviewCompatiblePlaceholder>
  );
};
