import { StyledWidgetContentBlock as WidgetContentBlock } from '../ContentBlocks/WidgetContentBlock.jsx';
import { TopicCluster } from '../ContentBlocks/TopicCluster';
import {
  StyledTopicClusterAutomatic as TopicClusterAutomatic,
} from '../ContentBlocks/TopicClusterAutomatic';
import { StyledBreakingNews as BreakingNews } from '../ContentBlocks/BreakingNews';
import { templates } from './coCoContentBlockTemplates';
import { geoRegionBottomLinkFn, geoRegionHeadlineFn } from '../ContentBlocks/Stories/HighPriorityStories/HighPriorityStories.jsx';
import { MostViewed } from '../ContentBlocks/MostViewed/MostViewed.jsx';
import { StyledFeaturedAV as FeaturedAV } from '../ContentBlocks/FeaturedAV/FeaturedAV.jsx';
import { AVCarouselManualTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselManualTopicVideoAdapter.jsx';
import { AVCarouselGlobalTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselGlobalTopicVideoAdapter.jsx';
import { AVCarouselProfileTopicVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselProfileTopicVideoAdapter.jsx';
import { AVCarouselRegionVideoAdapter } from '../ContentBlocks/AVCarousel/AVCarouselRegionVideoAdapter.jsx';
import { StyledTopicClusterAV as TopicClusterAV } from '../ContentBlocks/TopicClusterAV/TopicClusterAV.jsx';
import { StyledCrossPromotion as CrossPromotion } from '../ContentBlocks/CrossPromotion/CrossPromotion.jsx';
import { StyledManualContentList as ManualContentList } from '../ContentBlocks/ManualContentList/ManualContentList.jsx';
import { AllProgramsTeaserInterfaceAdapter } from '../ContentBlocks/AllProgramsTeaser/AllProgramsTeaserInterfaceAdapter.jsx';

// based on:
// https://confluence.dw.com/display/ROAD/NEST+CMS+CoCo+Templates+and+Components
// for streaming config go to src/components/CoCo/StreamingNavigation/streamingCoCoContentBlockConfig.js
// the values in each config are supposed to be unique, if a content block is supposed to appear on multiple pages
// move it to 'shared' config at the bottom
export const languageStartPageContentBlockConfig = {
  TOP_STORY: {
    type: 'TOP_STORY',
    ...templates.TopStory,
  },
  BREAKING_NEWS_WEBAPP: {
    type: 'BREAKING_NEWS_WEBAPP',
    cocoCmp: BreakingNews,
  },
  NEWS: {
    type: 'NEWS',
    ...templates.CurrentNews,
  },
  HIGH_PRIORITY_STORIES: {
    type: 'HIGH_PRIORITY_STORIES',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  HIGH_PRIORITY_STORIES_LIST: {
    type: 'HIGH_PRIORITY_STORIES_LIST',
    ...templates.DwRecommends,
    extraProps: {
      headlineTranslation: 'content_block.high_priority_stories.headline',
    },
  },
  MOST_CLICKED: {
    type: 'MOST_CLICKED',
    cocoCmp: MostViewed,
    extraProps: {
      headlineTranslation: 'content_block.most_clicked.headline',
    },
  },
  DW_RECOMMENDS: {
    type: 'DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  OPINION: {
    type: 'OPINION',
    ...templates.Opinion,
  },
  PANORAMA: {
    type: 'PANORAMA',
    ...templates.Panorama,
  },
};

export const thematicFocusPageContentBlockConfig = {
  TOP_STORY_THEMATIC_FOCUS: {
    type: 'TOP_STORY_THEMATIC_FOCUS',
    ...templates.TopStory,
  },
  NEWS_THEMATIC_FOCUS: {
    type: 'NEWS_THEMATIC_FOCUS',
    ...templates.CurrentNews,
  },
  STORIES_THEMATIC_FOCUS: {
    type: 'STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  HUB_THEMATIC_FOCUS: {
    type: 'HUB_THEMATIC_FOCUS',
    ...templates.Hub,
    extraProps: {
      headlineTranslation: 'content_block.hub_thematic_focus.headline',
    },
  },
  RELATED_STORIES_THEMATIC_FOCUS: {
    type: 'RELATED_STORIES_THEMATIC_FOCUS',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.related_stories_thematic_focus.headline',
    },
  },
  DW_RECOMMENDS_THEMATIC_FOCUS: {
    type: 'DW_RECOMMENDS_THEMATIC_FOCUS',
    ...templates.DwRecommends,
  },
  OPINION_THEMATIC_FOCUS: {
    type: 'OPINION_THEMATIC_FOCUS',
    ...templates.Opinion,
  },
  PANORAMA_THEMATIC_FOCUS: {
    type: 'PANORAMA_THEMATIC_FOCUS',
    ...templates.Panorama,
  },
};

export const regionPageContentBlockConfig = {
  TOP_STORY_REGION: {
    type: 'TOP_STORY_REGION',
    ...templates.TopStory,
  },
  NEWS_REGION: {
    type: 'NEWS_REGION',
    ...templates.CurrentNews,
  },
  STORIES_LIST_REGION: {
    type: 'STORIES_LIST_REGION',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_region.headline',
    },
  },
  HUB_REGION: {
    type: 'HUB_REGION',
    ...templates.GeoLinkHubStories,
    extraProps: {
      headlineTranslation: 'content_block.hub_region.headline',
      hubHeadlineFn: geoRegionHeadlineFn,
      hubBottomElementFn: geoRegionBottomLinkFn,
    },
  },
  DW_RECOMMENDS_REGION: {
    type: 'DW_RECOMMENDS_REGION',
    ...templates.DwRecommends,
  },
  OPINION_REGION: {
    type: 'OPINION_REGION',
    ...templates.Opinion,
  },
  PANORAMA_REGION: {
    type: 'PANORAMA_REGION',
    ...templates.Panorama,
  },
};

export const countryPageContentBlockConfig = {
  TOP_STORY_COUNTRY: {
    type: 'TOP_STORY_COUNTRY',
    ...templates.TopStory,
  },
  NEWS_COUNTRY: {
    type: 'NEWS_COUNTRY',
    ...templates.CurrentNews,
  },
  STORIES_LIST_COUNTRY: {
    type: 'STORIES_LIST_COUNTRY',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_country.headline',
    },
  },
  DW_RECOMMENDS_COUNTRY: {
    type: 'DW_RECOMMENDS_COUNTRY',
    ...templates.DwRecommends,
  },
  OPINION_COUNTRY: {
    type: 'OPINION_COUNTRY',
    ...templates.Opinion,
  },
  PANORAMA_COUNTRY: {
    type: 'PANORAMA_COUNTRY',
    ...templates.Panorama,
  },
};

export const autoTopicPageContentBlockConfig = {
  ATS_TOP_STORY: {
    type: 'ATS_TOP_STORY',
    ...templates.TopStory,
  },
  ATS_NEWS: {
    type: 'ATS_NEWS',
    ...templates.CurrentNews,
  },
  ATS_STORIES_LIST: {
    type: 'ATS_STORIES_LIST',
    ...templates.Stories,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  ATS_DW_RECOMMENDS: {
    type: 'ATS_DW_RECOMMENDS',
    ...templates.DwRecommends,
  },
  ATS_OPINION: { // it has been removed?
    type: 'ATS_OPINION',
    ...templates.Opinion,
  },
};

export const videoPageContentBlockConfig = {
  FEATURED_VIDEO: {
    type: 'FEATURED_VIDEO',
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_video.headline',
    },
  },
  MANUAL_TOPIC_VIDEO: {
    type: 'MANUAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselManualTopicVideoAdapter,
  },
  GLOBAL_TOPIC_VIDEO: {
    type: 'GLOBAL_TOPIC_VIDEO',
    cocoCmp: AVCarouselGlobalTopicVideoAdapter,
  },
  NEWS_VIDEO: {
    type: 'NEWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_videos.headline',
    },
  },
  PROFILE_TOPIC_VIDEO: {
    type: 'PROFILE_TOPIC_VIDEO',
    cocoCmp: AVCarouselProfileTopicVideoAdapter,
  },
  REPORTS_VIDEO: {
    type: 'REPORTS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_videos.headline',
    },
  },
  REGION_VIDEO: {
    type: 'REGION_VIDEO',
    cocoCmp: AVCarouselRegionVideoAdapter,
  },
  DW_RECOMMENDS_VIDEO: {
    type: 'DW_RECOMMENDS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  TOPIC_CLUSTER_VIDEO: {
    type: 'TOPIC_CLUSTER_VIDEO',
    cocoCmp: TopicClusterAV,
  },
  FULL_SHOWS_VIDEO: {
    type: 'FULL_SHOWS_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.full_shows_videos.headline',
    },
  },
  PANORAMA_VIDEO: {
    type: 'PANORAMA_VIDEO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
};

export const audioPageContentBlockConfig = {
  FEATURED_AUDIO: {
    type: 'FEATURED_AUDIO',
    cocoCmp: FeaturedAV,
    extraProps: {
      headlineTranslation: 'content_block.featured_audio.headline',
    },
  },
  NEWS_AUDIO: {
    type: 'NEWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.news_audios.headline',
    },
  },
  REPORTS_AND_SHOWS_AUDIO: {
    type: 'REPORTS_AND_SHOWS_AUDIO',
    ...templates.AvCarousel,
    extraProps: {
      headlineTranslation: 'content_block.reports_and_shows_audios.headline',
    },
  },
};

export const sharedContentBlockConfig = {
  WIDGET_WEBAPP: {
    type: 'WIDGET_WEBAPP',
    cocoCmp: WidgetContentBlock,
  },
  DOSSIER: {
    type: 'DOSSIER',
    cocoCmp: TopicCluster,
  },
  CROSS_PROMOTION: {
    type: 'CROSS_PROMOTION',
    cocoCmp: CrossPromotion,
  },
  ATS_TOPIC_CLUSTER: {
    type: 'ATS_TOPIC_CLUSTER',
    cocoCmp: TopicClusterAutomatic,
  },
  AV_NEWS: {
    type: 'AV_NEWS',
    ...templates.Av,
    extraProps: {
      isAVNews: true,
    },
  },
  AV_MAGAZINE: {
    type: 'AV_MAGAZINE',
    ...templates.Av,
  },
  MANUAL_CONTENTS: {
    type: 'MANUAL_CONTENTS',
    cocoCmp: ManualContentList,
    extraProps: {
      headlineTranslation: 'content_block.stories_thematic_focus.headline',
    },
  },
  C_All_PROGRAMS_OVERVIEW: {
    type: 'C_All_PROGRAMS_OVERVIEW',
    cocoCmp: AllProgramsTeaserInterfaceAdapter,
  },
};

export const defaultNewsContentBlockConfig = {
  ...languageStartPageContentBlockConfig,
  ...thematicFocusPageContentBlockConfig,
  ...regionPageContentBlockConfig,
  ...countryPageContentBlockConfig,
  ...autoTopicPageContentBlockConfig,
  ...videoPageContentBlockConfig,
  ...audioPageContentBlockConfig,
  ...sharedContentBlockConfig,
};
