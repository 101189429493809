import { createContext, useContext } from 'react';
import { useForcedTheme } from '../useForcedTheme';

export const ThemeContext = createContext({ isDarkTheme: false });

export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useForcedTheme();

  return (
    <ThemeContext.Provider value={{ isDarkTheme, setIsDarkTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
