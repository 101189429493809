import { StyledCurrentNews as CurrentNews } from '../ContentBlocks/CurrentNews';
import { TopStoryInterfaceAdapter } from './adapters/TopStoryInterfaceAdapter.jsx';
import { StyledStoriesHub as StoriesHub } from '../ContentBlocks/Stories/StoriesHub';
import { StyledStoriesList as StoriesList } from '../ContentBlocks/Stories/StoriesList';
import { StyledDwRecommendsTopics as DwRecommendsTopics } from '../ContentBlocks/DWRecommends';
import { StyledPanoramaItems as PanoramaItems } from '../ContentBlocks/Panorama';
import { StyledOpinion as Opinion } from '../ContentBlocks/Opinion';
import { StyledAVShowPlayer as AVShowPlayer } from '../ContentBlocks/AVShowPlayer';
import { StyledAVCarousel as AVCarousel } from '../ContentBlocks/AVCarousel';

export const templates = {
  CurrentNews: {
    cocoCmp: CurrentNews,
  },
  TopStory: {
    cocoCmp: TopStoryInterfaceAdapter,
  },
  Stories: {
    cocoCmp: StoriesList,
  },
  GeoLinkHubStories: {
    cocoCmp: StoriesHub,
    includesMultipleConfigs: true,
  },
  Hub: {
    cocoCmp: StoriesHub,
    includesMultipleConfigs: true,
  },
  DwRecommends: {
    cocoCmp: DwRecommendsTopics,
    extraProps: {
      headlineTranslation: 'content_block.dw_recommends.headline',
    },
  },
  Panorama: {
    cocoCmp: PanoramaItems,
    extraProps: {
      headlineTranslation: 'content_block.panorama.headline',
    },
  },
  Opinion: {
    cocoCmp: Opinion,
    extraProps: {
      headlineTranslation: 'content_block.opinion.headline',
    },
  },
  Av: {
    cocoCmp: AVShowPlayer,
  },
  AvCarousel: {
    cocoCmp: AVCarousel,
  },
};
