import { useInView } from 'react-intersection-observer';

import { useEffect } from 'react';
import { INTERSECTION_OBSERVER_SETTINGS } from './LazyLoadDwPicture.jsx';
import { convertTelegramNodes } from '../utils/telegram';
import { StyledCmpPreviewCompatiblePlaceholder as CmpPreviewCompatiblePlaceholder } from './CmpPreviewCompatiblePlaceholder.jsx';
import { useThemeContext } from './hooks/themeContext';

export const TelegramPost = ({ className, telegramPostId, isSinglePicture }) => {
  const { isDarkTheme } = useThemeContext();
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);

  useEffect(() => {
    if (inView) {
      convertTelegramNodes();
    }
  }, [inView]);
  return (
    <CmpPreviewCompatiblePlaceholder className={className} forwardRef={inViewRef}>
      <blockquote
        className={inView ? 'telegram-post' : ''}
        data-telegram
        data-telegram-post={telegramPostId}
        data-single={isSinglePicture}
        data-dark={isDarkTheme}
      />
    </CmpPreviewCompatiblePlaceholder>
  );
};

export const StyledTelegramPost = TelegramPost;
