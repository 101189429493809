import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { useThemeContext } from '../hooks/themeContext';

export const withTheme = Component => darkStyleClass => {
  const ComponentDark = extendWithClassNames(Component)(darkStyleClass);

  return ({ hasTheme, ...props }) => {
    const { isDarkTheme } = useThemeContext();

    if (isDarkTheme || hasTheme) {
      return <ComponentDark {...props} />;
    }
    return <Component {...props} />;
  };
};
