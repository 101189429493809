import { css } from '@linaria/core';
import { aspectRatioAware } from './css';

export const ultraWideScreenHeroClassName = css`
  ${aspectRatioAware.xl.ultraWide`
    height: calc(100vh - 70px);
    max-height: 700px;
    overflow: hidden;
  `}
`;

export const ultraWideScreenCenterHeroImagesClassName = css`
 ${aspectRatioAware.xl.ultraWide`
    figure {
      transform: translate(-50%,-50%);
      top:50%;
      left: 50%;

      img {
        width: auto;
        height: 100%;
      }
    }
  `}
`;
