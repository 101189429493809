import gql from 'graphql-tag';

import { StyledResponsiveDwPicture as ResponsiveDwPicture, responsiveDwPictureFragment } from '../../ResponsiveDwPicture';
import { StyledHeroImage as HeroImage } from '../../HeroImage/HeroImage.jsx';
import { StyledExternalImage as ExternalImage } from '../../ExternalImage';
import { useContentTeaserContext } from './ContentTeaserContext';
import { makeMemoFragment } from '../../../utils/graphql';
import { useFrontendConfig } from '../../FrontendConfigContext.jsx';
import { DW_IMAGE_SUFFIX_REGEX } from '../../hooks/useLazyPosterImage';
import { isInternalDwImageUrl } from '../../../utils/imgUtils';
import { LinkBase } from '../../Links/LinkBase';
import { MaybeC2paPin } from '../../hooks/c2paContext/MaybeC2paPin';

export const teaserLinkedImageFragment = makeMemoFragment({
  name: 'TeaserLinkedImage',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on UrlAspect {
          namedUrl
        }
        ... on NamedAspect {
          title
        }
        ... on AssociationsAspect {
          mainContentImageLink {
            targetId
            additionalInformation
            target {
              ...${responsiveDwPictureFragment.name}
            }
          }
        }
        ... on PlaybackResourceAspect {
          posterImageUrl
        }
      }
      ${responsiveDwPictureFragment.fragment()}
    `;
  },
});

const makeFakeFallbackImage = ({ posterImageUrl, staticBaseHost }) => {
  if (!isInternalDwImageUrl({ imageUrl: posterImageUrl, staticBaseHost })) {
    return null;
  }
  return {
    // eslint-disable-next-line no-template-curly-in-string
    staticUrl: posterImageUrl.replace(DW_IMAGE_SUFFIX_REGEX, '_${formatId}.$1'),
  };
};

export const TeaserLinkedImage = ({
  className,
}) => {
  const {
    config: { formatConfig, isPreloadImage },
    content,
  } = useContentTeaserContext();
  const { staticBaseHost } = useFrontendConfig();

  const { mainContentImageLink, posterImageUrl, title } = content;
  const { additionalInformation, target } = mainContentImageLink || {};
  const image = target || makeFakeFallbackImage({ posterImageUrl, staticBaseHost });
  const ImageTag = isPreloadImage ? HeroImage : ResponsiveDwPicture;

  return (
    <>
      <LinkBase className={className} title={title} to={content.namedUrl} tabIndex={-1}>
        {image
          ? (
            <ImageTag
              image={image}
              alt={additionalInformation}
              formatConfig={formatConfig}
            />
          ) : (
            <ExternalImage
              formatConfig={formatConfig}
              url={posterImageUrl}
            />
          )
        }
      </LinkBase>
      <MaybeC2paPin staticUrl={image?.staticUrl} />
    </>
  );
};
