import { css, cx } from '@linaria/core';

import { colors } from '../../utils/css';
import { useFrontendConfig } from '../FrontendConfigContext.jsx';
import { SsrCompatibleSingleResolutionGptSlot as SingleResolutionGptSlot } from './SingleResolutionGptSlot';
import { InfoAds } from '../Text/Information.jsx';
import { useTranslation } from '../hooks/useTranslation.jsx';
import { useMobileAppContext } from '../hooks/mobileAppContext';
import { AdContextProvider } from './AdContext';
import { AdvertisementBox } from './AdvertisementBox.jsx';
import { richTextAlignmentClassName } from '../layouts/ContentMoveUpLayout/ContentArea';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';

export const GptSlot = ({ slotConfig, className }) => {
  const { adsEnabled } = useFrontendConfig();
  const { isMobileMode } = useMobileAppContext();
  const labelTranslation = useTranslation('general.advertisement.ad_label');

  if (!adsEnabled || isMobileMode || !slotConfig) {
    return null;
  }

  return (
    <AdContextProvider slotConfig={slotConfig}>
      <AdvertisementBox className={cx(commonGptSlotClassName, className)}>
        <InfoAds className="label-top">{labelTranslation}</InfoAds>
        {slotConfig.map(slotConfigEntry => (
          <SingleResolutionGptSlot
            key={slotConfigEntry.adUnitCode}
            slotConfigEntry={slotConfigEntry}
            className="gpt-slot"
          />
        ))}
      </AdvertisementBox>
    </AdContextProvider>
  );
};

export const cssVariables = {
  gptSlotBackgroundColor: '--gpt-slot-background-color',
};

const darkGptSlotStyles = cx(
  InfoAds.darkStyles,
  css`${cssVariables.gptSlotBackgroundColor}: ${colors.BLUE_GREY_04};`,
);

export const commonGptSlotClassName = css`
  display: grid;
  justify-content: center;
  clear: both;
  grid-template-areas:
      ". ad-label-top ."
      "ad ad ad";
  padding-bottom: 30px;

  .label-top {
    grid-area: ad-label-top;
    font-size: 1.14rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1;
  }

  .gpt-slot {
    grid-area: ad;
    outline: solid 15px var(${cssVariables.gptSlotBackgroundColor}, ${colors.WARM_GREY_02});
    background-color: var(${cssVariables.gptSlotBackgroundColor}, ${colors.WARM_GREY_02});
  }
`;

const premiumGptSlotOuterBackgroundColor = css`
  background-color: ${colors.DARK_BLUE_GREY_02};
`;

const slotTopSpacing = css`
  padding-top: 7px;
`;

GptSlot.darkStyles = darkGptSlotStyles;

export const BeforeRichTextGptSlot = extendWithClassNames(GptSlot)(
  commonGptSlotClassName,
  richTextAlignmentClassName,
);
BeforeRichTextGptSlot.darkStyles = GptSlot.darkStyles;

export const AfterRichTextGptSlot = extendWithClassNames(GptSlot)(
  commonGptSlotClassName,
  richTextAlignmentClassName,
  slotTopSpacing,
);
AfterRichTextGptSlot.darkStyles = GptSlot.darkStyles;

export const PremiumGptSlot = extendWithClassNames(GptSlot)(
  commonGptSlotClassName,
  slotTopSpacing,
  premiumGptSlotOuterBackgroundColor,
);
PremiumGptSlot.darkStyles = GptSlot.darkStyles;
