import { StyledExpandable as Expandable } from '../../Expandable';
import { StyledContentBlock as ContentBlock } from '../ContentBlock';
import { MostViewedList, mostViewedListFragment } from './MostViewedList.jsx';

export const mostViewedFragment = mostViewedListFragment;

const focusContainerClassName = 'most-viewed-item';

export const MostViewed = ({ className, contents }) => (
  <ContentBlock
    headlineTranslation="content_block.most_clicked.headline"
    className={className}
    headlineIsA="h2"
    pageSectionId="most-viewed"
  >
    <Expandable
      dataArray={contents}
      initialListCount={5}
      stepMoreAmount={5}
      focusContainerClass={focusContainerClassName}
    >
      {renderedContents => (
        <MostViewedList contents={renderedContents} focusContainerClassName={focusContainerClassName} />
      )}
    </Expandable>
  </ContentBlock>
);

MostViewed.darkStyles = MostViewedList.darkStyles;
