import {
  Route, Switch, useParams,
} from './dwouter';

import { Page } from './components/Page';

import { ContentDetailsPage } from './pages/ContentDetailsPage.jsx';
import { PersonDetailsPage } from './pages/PersonDetailsPage.jsx';
import { NavigationPage } from './pages/NavigationPage.jsx';
import { AutoTopicPage } from './pages/AutoTopicPage.jsx';
import { NotFoundPage } from './pages/NotFoundPage.jsx';
import { LivestreamPage } from './pages/LivestreamPage.jsx';
import { AzIndexPage } from './pages/AzIndexPage.jsx';
import { HeadlinesOverviewPage } from './pages/HeadlinesOverviewPage.jsx';
import { ProgramsOverviewPage } from './pages/ProgramsOverviewPage.jsx';
import { ProgramDetailsPage } from './pages/ProgramDetailsPage.jsx';
import { VendorConsentPage } from './pages/VendorConsentPage.jsx';

import { urlPrefixToType } from './utils/url';

import { StartPageRedirect } from './components/StartPageRedirect.jsx';
import { AVRedirect } from './components/AVRedirect.jsx';
import { HomePage } from './components/HomePage';
import { suspendedComponentFactory } from './components/SuspendedComponent/suspendedComponentFactory.jsx';
import { LiveblogPostSharingPage } from './pages/LiveblogPostSharingPage.jsx';
import {
  anyFromLanguageRegexp,
  azIndexRegexp,
  contentPaginatedRegexp,
  contentRegexp,
  contentWithPageNumOldRegexp,
  headlinesOverviewRegexp,
  liveTvRegexp,
  liveblogRegexp,
  notFound404Regexp,
  privacySettingsRegexp,
  programsOverviewRegexp,
  startPageRedirectRegexp,
  peachExperimentRegexp,
  searchPaginatedRegexp,
  streamingVideoDetailRegexp,
  streamingNavigationRegexp,
} from './supportedUrls';

const { SearchPage } = suspendedComponentFactory(() => import('./pages/SearchPage/index.jsx'));
const { PeachExperimentPage } = suspendedComponentFactory(() => import('./pages/experimental/PeachExperimentPage.jsx'));
const { StreamingVideoDetailsPage } = suspendedComponentFactory(() => import('./pages/StreamingVideoDetailsPage.jsx'));
const { StreamingNavigationPage } = suspendedComponentFactory(() => import('./pages/StreamingNavigationPage.jsx'));

const typeToPage = {
  ARTICLE: {
    type: 'Article',
    component: ContentDetailsPage,
  },
  VIDEO: {
    type: 'Video',
    component: ContentDetailsPage,
  },
  AUDIO: {
    type: 'Audio',
    component: ContentDetailsPage,
  },
  IMAGE_GALLERY: {
    type: 'ImageGallery',
    component: ContentDetailsPage,
  },
  LIVEBLOG: {
    type: 'Liveblog',
    component: ContentDetailsPage,
  },
  PERSON: {
    component: PersonDetailsPage,
  },
  NAVIGATION: {
    component: NavigationPage,
  },
  AUTO_TOPIC: {
    component: AutoTopicPage,
  },
  CLASSIC_AV: {
    component: AVRedirect,
  },
  PROGRAM: {
    component: ProgramDetailsPage,
  },
};

export const ContentPage = props => {
  const params = useParams();

  const pageDef = typeToPage[urlPrefixToType(params.typeId)] || { component: NotFoundPage };
  const Tag = pageDef.component;
  return (
    <Tag {...props} type={pageDef.type}/>
  );
};

const typeToPagePaginated = {
  AUTO_TOPIC: {
    component: AutoTopicPage,
  },
};

export const ContentPagePaginated = props => {
  const params = useParams();

  const pageDef = typeToPagePaginated[urlPrefixToType(params.typeId)] || { component: NotFoundPage };
  const Tag = pageDef.component;
  return (
    <Tag {...props} type={pageDef.type}/>
  );
};

export const App = () => (
  <Page>
    <Switch>
      <Route path="/" component={HomePage} />
      <Route path={startPageRedirectRegexp} component={StartPageRedirect}/>
      <Route path={peachExperimentRegexp} component={PeachExperimentPage}/>
      <Route path={notFound404Regexp} component={NotFoundPage}/>
      <Route path={privacySettingsRegexp} component={VendorConsentPage}/>
      <Route path={liveTvRegexp} component={LivestreamPage}/>
      <Route path={azIndexRegexp} component={AzIndexPage}/>
      <Route path={headlinesOverviewRegexp} component={HeadlinesOverviewPage}/>
      <Route path={programsOverviewRegexp} component={ProgramsOverviewPage}/>
      <Route path={liveblogRegexp} component={LiveblogPostSharingPage}/>
      <Route path={searchPaginatedRegexp} component={SearchPage}/>
      <Route path={streamingVideoDetailRegexp} component={StreamingVideoDetailsPage}/>
      <Route path={streamingNavigationRegexp} component={StreamingNavigationPage}/>
      <Route path={contentPaginatedRegexp} component={ContentPagePaginated}/>
      <Route path={contentRegexp} component={ContentPage}/>
      <Route path={contentWithPageNumOldRegexp} component={ContentPage}/>
      <Route path={anyFromLanguageRegexp} component={NotFoundPage}/>
      <Route component={NotFoundPage} />
    </Switch>
  </Page>
);
