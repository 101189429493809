import { cx } from '@linaria/core';
import { Redirect, useLocation, useParams } from '../dwouter';
import { HeaderWithTheme } from '../components/Header';
import { CoCoPageHeadlineInHeaderWithTheme } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { StyledFooter as Footer } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { PremiumGptSlot } from '../components/GptSlot';
import { overviewPremiumSlotConfig } from '../components/GptSlot/slotConfigs';

import { PageWithData } from './PageWithData.jsx';
import { NotFoundPage } from './NotFoundPage.jsx';
import { useFrontendConfig } from '../components/FrontendConfigContext.jsx';
import { PageHeaderMetadata } from '../components/PageHeaderMetadata.jsx';
import { ProgramsOverviewCoCo } from '../components/CoCo/ProgramsOverview';
import { isPathMatching } from '../components/commons/PathAnalyser';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';

export const ProgramsOverviewPageNoQuery = ({ content, className }) => {
  const { showProgramsOverview = false } = useFrontendConfig();

  if (!showProgramsOverview) {
    return (
      <NotFoundPage />
    );
  }
  return (
    <>
      <PageHeaderMetadata content={content}/>
      <QuickNavigationList content={content}/>
      <HeaderWithTheme content={content}>
        {({ isHeaderInAlternateState }) => (isHeaderInAlternateState
          ? (
            <CoCoPageHeadlineInHeaderWithTheme navigation={content} />
          )
          : null
        )}
      </HeaderWithTheme>
      <div className={className }>
        <BrowserOnlyBreakingBanner />
        <PremiumGptSlot slotConfig={overviewPremiumSlotConfig}/>
        <ProgramsOverviewCoCo content={content} />
      </div>
      <Footer content={content}/>
    </>
  );
};

ProgramsOverviewPageNoQuery.darkStyles = cx(
  PremiumGptSlot.darkStyles,
  ProgramsOverviewCoCo.darkStyles,
);

export const ProgramsOverviewPageNoQueryWithTheme = withTheme(ProgramsOverviewPageNoQuery)(ProgramsOverviewPageNoQuery.darkStyles);

export const ProgramsOverviewPage = () => {
  const { langCode } = useParams();

  const queryDef = {
    path: `${langCode}/programs-overview`,
    depts: [langCode],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.programsOverview}>
      {({ programsOverview }) => {
        if (!programsOverview) {
          return (
            <NotFoundPage />
          );
        }
        const { namedUrl } = programsOverview;

        if (!isPathMatching({ namedUrl, pathname })) {
          return <Redirect to={{ pathname: namedUrl, search }} />;
        }

        return (
          <ProgramsOverviewPageNoQueryWithTheme content={programsOverview} />
        );
      }}
    </PageWithData>
  );
};
