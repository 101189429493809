import { isSafari } from '../commons';
import { hasVideoPlayer, hasAudioPlayer } from './playbackTypeMatcher';

const commonOptions = {
  bigPlayButton: false,
  html5: {
    vhs: {
      overrideNative: !isSafari(),
      useDtsForTimestampOffset: true,
    },
    nativeAudioTracks: false,
    nativeVideoTracks: false,
  },
};

export const videoOptions = Object.freeze({
  ...commonOptions,
});

export const liveVideoOptions = Object.freeze({
  ...videoOptions,
  html5: {
    ...videoOptions.html5,
    vhs: {
      ...videoOptions.html5.vhs,
      useDtsForTimestampOffset: false,
    },
  },
  liveui: true,
});

export const audioOptions = Object.freeze({
  ...commonOptions,
  controlBar: {
    pictureInPictureToggle: false,
    fullscreenToggle: false,
  },
  playbackRates: [1, 1.3, 1.5],
});

const importPlayerPlugins = async content => {
  const modules = await Promise.all(
    [
      hasVideoPlayer(content) && import('./videoPlugins'),
      hasAudioPlayer(content) && import('./audioPlugins'),
    ].filter(Boolean),
  );

  modules.forEach(m => m.register());
};

export const loadVideoJsApi = async content => {
  const { videojs } = await import('./videojs');
  await importPlayerPlugins(content);

  return {
    playerLib: videojs(),
  };
};
