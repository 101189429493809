export const isTopStoryCoCoCo = coCoCo => coCoCo?.type.startsWith('TOP_STORY') || coCoCo?.type.endsWith('TOP_STORY');

export const isTopCoCoCo = coCoCo => isTopStoryCoCoCo(coCoCo) || coCoCo?.type.includes('FEATURED_');

const getCollectionName = cocoCmpConfig => (cocoCmpConfig.includesMultipleConfigs ? 'cocoContents' : 'contents');

export const getDataRequirementsForCoCoComponents = cocoCmpTypesToFragments => Object.values(cocoCmpTypesToFragments).map(cocoCmpConfig => {
  const collectionFieldName = getCollectionName(cocoCmpConfig);

  return `${cocoCmpConfig.type.toLowerCase()}: compositionComponents(type: ${cocoCmpConfig.type}) {
    id
    type
    order
    ${cocoCmpConfig.cocoConfigFragment ? `...${cocoCmpConfig.cocoConfigFragment.name}` : ''}
    ${collectionFieldName} {
      ...${cocoCmpConfig.mainFragment.name}
    }
  }`;
}).join('\n');
