import { cx } from '@linaria/core';
import { StyledArticleDetails as ArticleDetails, articleDetailsFragment, darkArticleDetailsStyles } from '../ArticleDetails.jsx';
import { VideoDetails, videoDetailsFragment } from '../VideoDetails.jsx';
import { StyledAudioDetails as AudioDetails, audioDetailsFragment, darkAudioDetailsStyles } from '../AudioDetails.jsx';
import {
  StyledImageGalleryDetails as ImageGalleryDetails,
  imageGalleryDetailsFragment,
  darkImageGalleryDetailsStyles,
} from '../ImageGalleryDetails.jsx';
import { StyledLiveblogDetails as LiveblogDetails, liveblogDetailsFragment, darkLiveblogDetailsStyles } from '../LiveblogDetails';

import { ContentType } from '../../utils/contentUtils';
import { Placeholder } from '../Placeholder.jsx';

const emptyFragment = {
  name: '',
  fragment() {
    return '';
  },
};

const DetailsZoneByType = {
  [ContentType.Article]: {
    component: ArticleDetails,
    fragment: articleDetailsFragment,
  },
  [ContentType.Audio]: {
    component: AudioDetails,
    fragment: audioDetailsFragment,
  },
  [ContentType.Video]: {
    component: VideoDetails,
    fragment: videoDetailsFragment,
  },
  [ContentType.ImageGallery]: {
    component: ImageGalleryDetails,
    fragment: imageGalleryDetailsFragment,
  },
  [ContentType.Liveblog]: {
    component: LiveblogDetails,
    fragment: liveblogDetailsFragment,
  },
  Unknown: {
    component: Placeholder,
    fragment: emptyFragment,
  },
};

export const detailsZoneFragmentFactory = ({ type }) => DetailsZoneByType[type].fragment;

export const DetailsZone = ({ content = {}, ...rest }) => {
  const typeConfig = DetailsZoneByType[content.__typename] || DetailsZoneByType.Unknown;
  const Tag = typeConfig.component;
  return (
    <Tag content={content} {...rest}/>
  );
};

// TODO: darkDetailsZoneStyles should not be exported in future (after we complete the migration to Component.darkStyles)
export const darkDetailsZoneStyles = cx(
  darkArticleDetailsStyles,
  darkAudioDetailsStyles,
  VideoDetails.darkStyles,
  darkImageGalleryDetailsStyles,
  darkLiveblogDetailsStyles,
);

DetailsZone.darkStyles = darkDetailsZoneStyles;
