import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { colors } from '../../utils/css';
import { authorsInfoFragment, AuthorsInfo } from './AuthorsInfo.jsx';
import { isValidList } from '../../utils/contentUtils';
import { feedbackFragment, Feedback } from '../Feedback';
import { useFeedbackDetector } from '../Feedback/utils';
import { makeMemoFragment } from '../../utils/graphql';

export const contentDetailFooterFragment = makeMemoFragment({
  name: 'ContentDetailFooter',
  fragment() {
    return gql`fragment ${this.name} on Content {
            ... on ContactAspect {
              contactType
            }
            ...${authorsInfoFragment.name}
            ...${feedbackFragment.name}
        }
        ${authorsInfoFragment.fragment()}
        ${feedbackFragment.fragment()}
    `;
  },
});

const hasShortProfile = person => !!person.shortProfile;

export const ContentDetailFooter = ({ content, className }) => {
  const { persons = [] } = content;
  const personsWithShortProfile = persons.filter(hasShortProfile);

  const showAuthorsInfo = isValidList(personsWithShortProfile);
  const { hasFeedback } = useFeedbackDetector(content);

  if (!showAuthorsInfo && !hasFeedback) {
    return null;
  }

  return (
    <footer className={className}>
      { showAuthorsInfo && (
        <AuthorsInfo persons={personsWithShortProfile}/>
      )}
      { hasFeedback && (
        <Feedback content={content} className={classnames('feedback', { 'authors-shown': showAuthorsInfo })} />
      )}
    </footer>
  );
};

export const darkContentDetailFooterStyles = cx(
  AuthorsInfo.darkStyles,
  Feedback.darkStyles,
);

export const StyledContentDetailFooter = styled(ContentDetailFooter)`
  border-top: 0.1px solid ${colors.BLUE_GREY_03};
  margin-bottom: 40px;

  .feedback {
    margin-top: 15px;

    &.authors-shown {
      margin-top: 30px;
    }
  }
`;
