import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { TextGrey } from '../../Text';
import { AuthorName, authorNameFragment } from './AuthorName.jsx';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';

export const authorsLineFragment = makeMemoFragment({
  name: 'AuthorsLine',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
      personAssociations: contentAssociations(targetTypes: PERSON) {
        targetId
        additionalInformation
      }
      persons {
          id
          ...${authorNameFragment.name}
      }
    }
    ${authorNameFragment.fragment()}
    `;
  },
});

const isFirst = index => index === 0;

export const AuthorsLine = ({ content, className }) => {
  if (!isValidList(content.persons)) {
    return null;
  }
  return (
    <div className={className}>
      {content.persons.map((person, index) => (
        <span key={person.id}>
          {!isFirst(index) &&
            <TextGrey className='separator'> | </TextGrey>
          }
          <AuthorName
            person={person}
            additionalInformation={content.personAssociations.find(pa => person.id === pa.targetId).additionalInformation}
          />
        </span>
      ))}
    </div>
  );
};

AuthorsLine.darkStyles = cx(
  TextGrey.darkStyles,
  AuthorName.darkStyles,
);
