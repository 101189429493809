import gql from 'graphql-tag';
import { partialFragments, Partials, StyledContentTeaser as ContentTeaser } from '../../ContentTeaserSnippets/ContentTeaserPartials';
import { makeMemoFragment } from '../../../utils/graphql';
import { LinkDefaultNarrow } from '../../Links';

export const mostViewedContentTeaserFragment = makeMemoFragment({
  name: 'MostViewedContentTeaser',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on ModelAspect {
        id
      }
      ... on UrlAspect {
          namedUrl
        }
      ... on NamedAspect {
        title
      }
      ...${partialFragments.linkedTitleFragment.name}
      ...${partialFragments.teaserFooterFragment.name}
    }
    ${partialFragments.linkedTitleFragment.fragment()}
    ${partialFragments.teaserFooterFragment.fragment()}
    `;
  },
});

export const MostViewedContentTeaser = ({
  content = {},
  className,
}) => (
  <ContentTeaser
    className={className}
    content={content}
    hideOpinion
  >
    <Partials.MainContentArea>
      <Partials.Data>
        <Partials.LinkedTitle isA='h3' />
      </Partials.Data>
    </Partials.MainContentArea>
  </ContentTeaser>
);

MostViewedContentTeaser.darkStyles = LinkDefaultNarrow.darkStyles;
