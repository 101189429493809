import { ExpandableTextOverlay } from './ExpandableTextOverlay.jsx';
import { StyledResponsiveSlideImage as SlideResponsiveImage } from './SlideResponsiveImage.jsx';
import { Copyright } from './Copyright.jsx';

export const SlideImage = ({
  galleryImage,
  className,
  onTextOverlayToggle,
  isTextOverlayOpened,
  isCurrentIndex,
  ...restProps
}) => {
  const { name: title, description: teaser } = galleryImage;
  return (
    <div className={className} {...restProps}>
      <ExpandableTextOverlay className="text-overlay"
        title={title}
        teaser={teaser}
        onTextOverlayToggle={onTextOverlayToggle}
        isTextOverlayOpened={isTextOverlayOpened}
        isCurrentIndex={isCurrentIndex}
      />
      <Copyright galleryImage={galleryImage} />
      <SlideResponsiveImage galleryImage={galleryImage}/>
    </div>
  );
};

export const StyledSlideImage = SlideImage;
