import { styled } from '@linaria/react';
import { Helmet } from 'react-helmet-async';
import { colors } from '../../../utils/css';
import '../../../bootstrap/custom.scss';

export const globalCssVariables = {
  flowDirection: '--flow-direction',
  lineHeight: '--line-height',
  counterStyle: '--counter-style',
};

// TODO linaria-next does it make sense to seperate this from LanguageBasedGlobalStyles?
export const FixedGlobalStyles = styled.div`
:global(){
  #root {
    background-color: ${colors.DARK_BLUE_GREY_02};
  }
  :root, *[dir="ltr"] {
    ${globalCssVariables.flowDirection}: 1;
  }
  *[dir="rtl"] {
    ${globalCssVariables.flowDirection}: -1;
  }
}
`;

const isAmpClassname = 'is-amp-page';
const isNotAmpClassname = 'is-not-amp-page';
export const isAmpSelector = `.${isAmpClassname}`;
export const isNotAmpSelector = `.${isNotAmpClassname}`;

export const LanguageBasedGlobalStyles = styled.div`
:global(){
  html {
    font-size: 10px;
    ${globalCssVariables.lineHeight}: 1.1;
    ${globalCssVariables.counterStyle}: decimal;
  }
  html[lang="hi"] {
    font-size: 12px;
    ${globalCssVariables.lineHeight}: 1.3;
    ${globalCssVariables.counterStyle}: devanagari;
  }
  html[lang="ar"] {
    font-feature-settings: "liga" 0;
    ${globalCssVariables.counterStyle}: arabic-indic;
  }
  html[lang="zh"] {
    ${globalCssVariables.counterStyle}: cjk-ideographic;
  }
  html[lang="bn"] {
    ${globalCssVariables.counterStyle}: bengali;
  }
  html[lang="fa"], html[lang="ps"], html[lang="ur"] {
    ${globalCssVariables.counterStyle}: persian;
  }
  html * {
    letter-spacing: normal;
  }
  ${isNotAmpSelector}[dir="rtl"] * {
    letter-spacing: 0 !important;
  }
}
`;

export const PageGlobalStyles = ({ isAmp = false }) => (
  <>
    <FixedGlobalStyles/>
    <LanguageBasedGlobalStyles />
    <Helmet htmlAttributes={{ class: isAmp ? isAmpClassname : isNotAmpClassname }}/>
  </>
);
