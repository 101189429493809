import { useLocationQueryParam } from '../useLocationQueryParam';

export const C2PA_QUERY_PARAM_NAME = 'c2pa';

const getC2paUrl = imgUrl => imgUrl.replace('.dw.com/image/', '.dw.com/image/c2pa/');

export const useC2paUrlIfEnabled = ({ imgUrl = '' }) => {
  const isC2paEnabled = useLocationQueryParam(C2PA_QUERY_PARAM_NAME) === '1';
  const signedUrl = isC2paEnabled ? getC2paUrl(imgUrl) : imgUrl;

  return signedUrl;
};
