import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import classnames from 'classnames';
import { makeMemoFragment } from '../../../utils/graphql';
import { resolutions, mediaMin, aspectRatioAware } from '../../../utils/css';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { alwaysLandscapePictureConfig } from '../../ResponsiveDwPicture/pictureConfigs';
import { StyledResponsiveDwPicture as ResponsiveDwPicture, responsiveDwPictureFragment } from '../../ResponsiveDwPicture';
import { isCaricature } from '../../../utils/imgUtils';
import { StyledHeroImage as HeroImage } from '../../HeroImage/HeroImage.jsx';
import { MaybeC2paPin } from '../../hooks/c2paContext/MaybeC2paPin';

export const slideResponsiveImageFragment = makeMemoFragment({
  name: 'SlideResponsiveImage',
  fragment() {
    return gql`fragment ${this.name} on GalleryImage {
      name
      description
      altText
      assignedImage {
        licenserSupplement
        imageType
        ...${responsiveDwPictureFragment.name}
      }
    }
    ${responsiveDwPictureFragment.fragment()}
  `;
  },
});

const commonSlideshowImageFormatConfig = [
  {
    minWidth: resolutions.min.xs,
    maxWidth: resolutions.max.xs,
    formatGroupName: '70X',
  },
  {
    minWidth: resolutions.min.sm,
    maxWidth: resolutions.max.sm,
    formatGroupName: '80X',
  },
];

export const slideshowImageFormatConfig = [
  ...commonSlideshowImageFormatConfig,
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.lg,
    formatGroupName: '60X',
  },
  {
    minWidth: resolutions.min.xl,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '100X',
  },
];

export const slideshowRichTextImageFormatConfig = [
  ...commonSlideshowImageFormatConfig,
  {
    minWidth: resolutions.min.md,
    maxWidth: resolutions.max.wide_xl,
    formatGroupName: '60X',
  },
];

const getFormatConfig = ({ isInRichText, isCaricatureImage }) => {
  if (isCaricatureImage) {
    return alwaysLandscapePictureConfig;
  }
  if (isInRichText) {
    return slideshowRichTextImageFormatConfig;
  }
  return slideshowImageFormatConfig;
};

export const SlideResponsiveImage = ({ className, galleryImage = {}, preload }) => {
  const { assignedImage, altText } = galleryImage;
  const pageContext = usePageContext();

  if (!assignedImage) {
    return null;
  }

  const isPreloadImage = preload && pageContext.isOnDetailPage && !pageContext.isInRichText;
  const isCaricatureImage = isCaricature(assignedImage.imageType);
  const ImageTag = isPreloadImage ? HeroImage : ResponsiveDwPicture;

  return (
    <>
      <ImageTag
        className={classnames(className, { 'is-caricature': isCaricatureImage, 'is-richtext': pageContext.isInRichText })}
        image={assignedImage}
        formatConfig={getFormatConfig({ isInRichText: pageContext.isInRichText, isCaricatureImage })}
        alt={altText}
      />
      <MaybeC2paPin staticUrl={assignedImage?.staticUrl} />
    </>
  );
};

export const StyledResponsiveSlideImage = styled(SlideResponsiveImage)`
    top: 50%;
    transform: translate3d(0, -50%, 0);
    ${mediaMin.xl`
      &.is-caricature {
        padding-bottom: 43.75%;
        margin: 0 auto;
        &:not(.is-richtext) {
          max-width: 77.77%;
        }
      }
    `}
    ${aspectRatioAware.xl.ultraWide`
      &.is-caricature {
        margin: 0;
      }
    `}
`;
