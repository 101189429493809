import gql from 'graphql-tag';
import { StyledAVCarousel as AVCarousel, avCarouselFragment } from './AVCarousel.jsx';
import { makeMemoFragment } from '../../../utils/graphql';
import { linkWithArrowFragment } from '../LinkWithArrow.jsx';

export const avCarouselGlobalTopicVideoAdapterFragment = avCarouselFragment;

export const avCarouselGlobalTopicVideoAdapterConfigurationFragment = makeMemoFragment({
  name: 'AVCarouselGlobalTopicVideoAdapterConfiguration',
  fragment() {
    return gql`fragment ${this.name} on CompositionComponent {
        configuration {
          sourceAssociations {
            targetId
            target {
              ... on NamedAspect {
                title
               }
              ...${linkWithArrowFragment.name}
            }
          }
        }
      }
      ${linkWithArrowFragment.fragment()}
    `;
  },
});

export const AVCarouselGlobalTopicVideoAdapter = ({ configuration, contents, ...otherProps }) => {
  const autoTopic = configuration.sourceAssociations[0]?.target;

  return (
    <AVCarousel
      headlineTranslation={autoTopic?.title}
      contents={contents}
      {...otherProps}
    />
  );
};
