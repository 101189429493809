import { styled } from '@linaria/react';

import { isCaricature, retrieveLayoutImage } from '../utils/imgUtils';
import { ImageBasicCaption } from './ImageBasicCaption.jsx';
import { mainImageWithCaptionFragment } from './ResponsiveDwPicture/mainImageWithCaptionFragment';
import { alwaysLandscapePictureConfig, heroPictureInTextConfig } from './ResponsiveDwPicture/pictureConfigs';
import { isUnifiedProgram } from '../utils/contentUtils';
import { richTextAlignmentStyles } from './layouts/ContentMoveUpLayout/ContentArea';
import { StyledHeroImage as HeroImage } from './HeroImage';
import { MaybeC2paPin } from './hooks/c2paContext/MaybeC2paPin';

export const detailPageHeroImageFragment = mainImageWithCaptionFragment;

export const DetailPageHeroImage = ({ content = {}, className }) => {
  const heroImage = retrieveLayoutImage(content);
  if (!heroImage) {
    return null;
  }

  const target = content?.mainContentImageLink?.target;
  const isCaricatureImage = isCaricature(heroImage?.imageType);
  const hasCaption = heroImage.caption || heroImage.licenserSupplement;
  const pictureFormatConfig = isCaricatureImage || isUnifiedProgram(content) ? alwaysLandscapePictureConfig : heroPictureInTextConfig;

  return (
    <figure className={className}>
      <HeroImage
        image={target}
        alt={heroImage.alt}
        formatConfig={pictureFormatConfig}
      />
      <MaybeC2paPin staticUrl={target?.staticUrl}/>
      {hasCaption &&
        <ImageBasicCaption caption={heroImage.caption} licenserSupplement={heroImage.licenserSupplement} />
      }
    </figure>
  );
};

export const StyledDetailPageHeroImage = styled(DetailPageHeroImage)`
  position: relative;
  ${richTextAlignmentStyles}
  margin-top: 20px;
  margin-bottom: 20px;
`;

StyledDetailPageHeroImage.darkStyles = ImageBasicCaption.darkStyles;
