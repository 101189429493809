import classNames from 'classnames';
import { forwardRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { styled } from '@linaria/react';
import { colors } from '../utils/css';
import { replaceFormatInUrl } from '../utils/imgUtils';
import globals from '../utils/globals';
import { useEventListener } from './hooks/useEventListener';
import { cssVariables as fontCssVariables } from './GlobalFonts';
import { useC2paUrlIfEnabled } from './hooks/c2paContext/useC2paUrlIfEnabled';

export const INTERSECTION_OBSERVER_SETTINGS = {
  triggerOnce: true,
  rootMargin: '1000px 1000px',
};

export const CUSTOM_EVENT_DISABLE_LAZY = 'disable-img-lazy';

export const LazyLoadDwPicture = forwardRef(({
  image, alt, className, lqFormat, hqFormat, isA = 'figure', style,
}, ownRef) => {
  const staticUrl = useC2paUrlIfEnabled({ imgUrl: image?.staticUrl });

  const { Image } = globals;
  const [inViewRef, inView] = useInView(INTERSECTION_OBSERVER_SETTINGS);

  const [lqLoaded, setLqLoaded] = useState(false);
  const [hqLoaded, setHqLoaded] = useState(false);
  const [forceDisableLazy, setForceDisableLazy] = useState(false);
  const [isDirectlyAvailableInCache, setIsDirectlyAvailableInCache] = useState(false);

  const allowLoadImages = inView || forceDisableLazy;

  useEffect(() => {
    if (allowLoadImages) {
      const hqImg = new Image();
      // eslint-disable-next-line fp/no-mutation
      hqImg.src = replaceFormatInUrl(staticUrl, hqFormat);
      if (hqImg.complete) {
        setIsDirectlyAvailableInCache(true);
        hqImg.decode().then(onHqLoad);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hqFormat, allowLoadImages]); // DO NOT include imgFormatFn here! Will re-render too many times

  const onHqLoad = () => {
    setHqLoaded(true);
  };

  const onLqLoad = () => {
    if (!hqLoaded) {
      const hqImg = new Image();
      // eslint-disable-next-line fp/no-mutation
      hqImg.src = replaceFormatInUrl(staticUrl, hqFormat);
      setLqLoaded(true);
      hqImg.decode().then(onHqLoad);
    }
  };

  useEventListener(ownRef.current, CUSTOM_EVENT_DISABLE_LAZY, () => setForceDisableLazy(true));

  const Tag = isA;
  return (
    <Tag
      className={`${className} lazy-load-container`}
      ref={ownRef}
      style={style}
    >
      <img
        ref={inViewRef}
        onLoad={onLqLoad}
        src={allowLoadImages ? replaceFormatInUrl(staticUrl, lqFormat) : ''}
        className={classNames('lq-img', { loaded: lqLoaded })}
        aria-hidden="true"
        alt={alt}
      />
      <img className={classNames('hq-img', { loaded: hqLoaded, 'quick-transition': isDirectlyAvailableInCache })}
        src={hqLoaded ? replaceFormatInUrl(staticUrl, hqFormat) : ''}
        alt={alt}
        title={alt}
      />

    </Tag>
  );
});
// TODO linaria-next css`` ??
export const dwPicStyles = `
  background-color: ${colors.BLUE_GREY_02};
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  margin: 0;
`;

export const dwPicImgStyles = `
  position: absolute;
  top: 0;
  inset-inline-start: 0;
  width: 100%;
  max-width: 100%;
`;

export const StyledLazyLoadDwPicture = styled(LazyLoadDwPicture)`
  ${dwPicStyles}

  img {
    ${dwPicImgStyles}
    opacity: 0;
    transition: opacity 1s linear;
    font-family: var(${fontCssVariables.systemFontFamily});
  }

  img.quick-transition {
    transition: opacity 250ms linear;
  }

  img.loaded {
    opacity: 1;
  }

  .lq-img {
    filter: blur(15px);
  }
`;
