import { css, cx } from '@linaria/core';
import { StyledTwitterIcon as TwitterIcon } from './TwitterIcon.jsx';
import { LinkDefault } from '../../Links';

export const TwitterLink = ({ twitterHandle, className }) => (
  <LinkDefault to={`https://twitter.com/${twitterHandle}`} title={`twitter — ${twitterHandle}`} className={cx(className, twitterLinkClass)}>
    <TwitterIcon className="twitter-icon"/>
    {twitterHandle}
  </LinkDefault>
);

TwitterLink.darkStyles = LinkDefault.darkStyles;

export const twitterLinkClass = css`
  display: flex;
  align-items: center;
  letter-spacing: 0.04rem;

  .twitter-icon {
    width: 1.1em;
    margin-inline-end: 5px;
  }
`;
