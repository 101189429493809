import { css, cx } from '@linaria/core';
import { useAdContext } from './AdContext';
import { hideOnPrint } from '../GlobalPrintStyles';

export const AdvertisementBox = ({ children, className }) => {
  const [{ showAdBox }] = useAdContext();

  return (
    <div className={cx(advertisementBoxClass, className, hideOnPrint, 'advertisement', !showAdBox && 'no-box')}>
      {children}
    </div>
  );
};

export const advertisementBoxClass = css`
  &.no-box {
    display: none;
  }
`;
