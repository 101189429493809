import { useRef, memo } from 'react';
import gql from 'graphql-tag';

import { css, cx } from '@linaria/core';
import { useLoadPlayerLibEffect } from '../../utils/video/useLoadPlayerLibEffect';
import { useRouterLinksEffect } from '../../utils/richtext/useRouterLinksEffect';
import { replacersFragments, usePlaceholderReplacementEffect } from '../../utils/richtext/usePlaceholderReplacementEffect';
import { useFeedbackButtonEffect } from '../../utils/richtext/useFeedbackButtonEffect';

import { colors, mediaMax, mediaMin } from '../../utils/css';
import {
  richTextAlignmentStyles, cssVariables as contentAreaCssVariables, listInlineStartVariables,
} from '../layouts/ContentMoveUpLayout/ContentArea';
import { makeMemoFragment } from '../../utils/graphql';
import { TextDefault } from '../Text';
import {
  cssVariables as textCssVariables,
  semiBoldStyles as semiBoldFontWeight,
  regularStyles as regularFontWeight,
} from '../Text/TextBase';
import {
  cssVariables as fontCssVariables,
} from '../GlobalFonts';
import { mediaTagStyles } from '../MediaTag.jsx';
import { InRichTextContext } from '../PageEmbeddingContext.jsx';
import { extendedLinkStyles, baseLinkStyles, StyledLinkBase } from '../Links/LinkBase';
import { captionBasicStyles, ImageBasicCaption } from '../ImageBasicCaption.jsx';
import { BrowserOnly } from '../BrowserOnly.jsx';
import { extendWithClassNames } from '../../utils/extendWithClassNames.jsx';
import { infoboxStylesString, infoboxLastChildNoMarginBottomStylesString, StyledInfobox } from '../Infobox.jsx';
import { globalCssVariables } from '../Page/PageGlobalStyles';

export const contentDetailRichTextFragment = makeMemoFragment({
  name: 'ContentDetailRichText',
  fragment() {
    return gql`fragment ${this.name} on Content {
        ... on TextualAspect {
          text
        }
        ... on AssociationsAspect {
          contentLinks {
            targetId
            isInText
          }
        }
        ${replacersFragments.map(frag => `...${frag.name}`).join('\n')}
    }
    ${replacersFragments.reduce((acc, frag) => gql`
      ${acc}
      ${frag.fragment()}
    `, '')}
    `;
  },
});

// @VisibleForTesting
export const RichTextPortals = ({ content, richTextRef, replacePlaceholders = true }) => {
  const portals = usePlaceholderReplacementEffect({ content, richTextRef });
  return replacePlaceholders && portals;
};

export const NotMemoContentDetailRichText = ({
  content, className, isA = 'div', replacePlaceholders,
}) => {
  const richTextRef = useRef();
  useLoadPlayerLibEffect(content);
  useRouterLinksEffect({ content, richTextRef });
  useFeedbackButtonEffect({ richTextRef });

  return (
    <InRichTextContext>
      <TextDefault
        isA={isA}
        ref={richTextRef}
        dangerouslySetInnerHTML={{ __html: content.text }}
        className={cx(className, 'rich-text')}
      />
      <BrowserOnly>
        <RichTextPortals content={content} richTextRef={richTextRef} replacePlaceholders={replacePlaceholders} />
      </BrowserOnly>
    </InRichTextContext>
  );
};
export const ContentDetailRichText = memo(NotMemoContentDetailRichText);

export const cssVariables = {
  linkUnderlineColor: '--rich-text-link-underline-color',
  floatingElementOuterSpacing: '--rich-text-floating-element-border-spacing',
  floatingElementTextSpacing: '--rich-text-floating-element-text-spacing',
  floatingElementWidth: '--rich-text-floating-element-width',
};

export const imageCssVariables = {
  background: '--detail-rich-text-image-background',
};

// TODO linaria-next css`` class name .... might not be possible with current richtext
export const richTextHeadlineCss = `
  font-size: 2rem;
  ${mediaMin.md`
    font-size: 2.4rem;
  `}
  ${mediaMin.lg`
    font-size: 2.8rem;
  `}
`;

export const richTextSemiBoldHeadlineCss = `
  ${semiBoldFontWeight};
  font-variation-settings: "opsz" var(${textCssVariables.pangeaOpticalSize}, 20), "ital" var(${textCssVariables.pangeaItalic}, 0);
  ${textCssVariables.pangeaOpticalSize}: 20;
  line-height: var(${globalCssVariables.lineHeight});
`;

const floatingElementsVariables = `
  ${cssVariables.floatingElementOuterSpacing}: 0px;
  ${cssVariables.floatingElementTextSpacing}: 0px;
  ${cssVariables.floatingElementWidth}: 100%;
  ${mediaMin.sm`
    ${cssVariables.floatingElementOuterSpacing}: 30px;
    ${cssVariables.floatingElementTextSpacing}: 15px;
    ${cssVariables.floatingElementWidth}: 45%;
  `}
  ${mediaMin.md`
    ${cssVariables.floatingElementOuterSpacing}: 24px;
    ${cssVariables.floatingElementWidth}: 46%;
  `}
  ${mediaMin.lg`
    ${cssVariables.floatingElementOuterSpacing}: 0px;
    ${cssVariables.floatingElementWidth}: 41%;
  `}
`;

export const contentDetailRichTextCss = css`
  display: flow-root;
  font-size: 1.6rem;
  ${mediaMin.md`
    font-size: 1.8rem;
  `}
  word-break: break-word;
  & > h2 {
    ${richTextHeadlineCss}
    ${richTextSemiBoldHeadlineCss}
    margin-top: 30px;
    margin-bottom: 20px;
  }
  ${richTextAlignmentStyles}
  ${floatingElementsVariables}

  & > h3 {
    ${richTextSemiBoldHeadlineCss}
    font-size: 1.8rem;
    margin-top: 24px;
    margin-bottom: 10px;
    ${mediaMin.md`
      font-size: 2rem;
    `}
    ${mediaMin.lg`
        font-size: 2.2rem;
    `}
  }

  p {
    margin-bottom: 20px;
  }

  button.feedback {
    background: none;
    border: none;
    padding: 0;
  }

  a, button.feedback {
    ${semiBoldFontWeight}
    ${baseLinkStyles}
    ${extendedLinkStyles}
    text-decoration: underline;
    text-decoration-color: var(${cssVariables.linkUnderlineColor}, ${colors.BLUE_GREY_04});
    &:visited, &:hover, &:active {
      text-decoration-color: currentColor;
    }
    &.external-link svg {
      margin: 0 0.35em 0.25em;
    }
    &.feedback svg {
      margin: 0 0.35em;
    }
  }

  *:is(ul, ol) {
    ${listInlineStartVariables}
    padding-block: 0px;
    padding-inline: var(${contentAreaCssVariables.listInlineStartSpacing}) var(${contentAreaCssVariables.contentAreaInlineSpacing});
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  strong, ol {
    ${semiBoldFontWeight};
  }

  ol > li > span {
   ${regularFontWeight};
  }

  li {
    margin-bottom: 1em;
  }

  em {
    font-variation-settings: "opsz" var(${textCssVariables.pangeaOpticalSize}), "ital" var(${textCssVariables.pangeaItalic});
    ${textCssVariables.pangeaItalic}: 1;
  }

  & > *:not(.placeholder-image, ul, ol, .embed, .simplebox) {
    padding: 0px var(${contentAreaCssVariables.contentAreaInlineSpacing});
  }

  img {
    width: 100%;
    min-width: 100%;
  }

  .placeholder-image {
    width: 100%;
    margin-bottom: 1em;

    img {
      background-color: var(${imageCssVariables.background}, ${colors.BLUE_GREY_02});
    }
    .img-caption {
      word-wrap: break-word;
      hyphens: auto;
      ${captionBasicStyles}
      .copyright {
        font-family: var(${fontCssVariables.systemFontFamily});
        font-style: var(${fontCssVariables.italicForSystemFont}, none);
      }
    }
    &.big {
      .img-caption {
        ${mediaMin.sm`
          padding: 10px 40px;
        `}
        ${mediaMin.lg`
          padding: 10px 15px;
        `}
      }
    }
    &:not(.big) {
      width: var(${cssVariables.floatingElementWidth});
      padding: 0px var(${cssVariables.floatingElementOuterSpacing});
      box-sizing: content-box;
      float: inline-start;
      &.left {
        padding-inline-end: var(${cssVariables.floatingElementTextSpacing});
      }
      &.right {
        float: inline-end;
        padding-inline-start: var(${cssVariables.floatingElementTextSpacing});
      }
      ${mediaMin.sm`
        margin-top: 0.5em;
      `}
      &.legacy_daily_image_portrait {
        ${mediaMin.sm`
          width: 30%;
        `}
        ${mediaMin.md`
          width: 31%;
        `}
        ${mediaMin.lg`
          width: 25%;
        `}
      }

      &.legacy_daily_image_landscape {
        ${mediaMin.lg`
          width: 33%;
        `}
        ${mediaMin.xl`
          width: 25%;
        `}
      }
    }
  }
  .embed {
    width: 100%;
    max-width: 100vw;
    margin-bottom: 1em;
    clear: both;
  }

  .simplebox,
  .vjs-wrapper:not(.big) {
    width: var(${cssVariables.floatingElementWidth});
    margin-inline: var(${cssVariables.floatingElementOuterSpacing});
    float: inline-start;
    &.left {
      margin-inline-end: var(${cssVariables.floatingElementTextSpacing});
    }
    &.right {
      float: inline-end;
      margin-inline-start: var(${cssVariables.floatingElementTextSpacing});
    }
  }

  .vjs-wrapper {
    display: flex;
    flex-direction: column-reverse;
    background: ${colors.BLACK};
    .headline {
      ${richTextSemiBoldHeadlineCss}
      letter-spacing: normal;
      color: ${colors.WHITE};
      margin: 0;
      padding: 15px;
      ${mediaMax.sm`
        font-size: 2.2rem;
      `}

      svg {
        ${mediaTagStyles}
      }
    }
    :is(video, audio):not(.vjs-tech) {
      height: 56.25vw;
    }
    &.big {
      .headline {
        padding: 20px var(${contentAreaCssVariables.contentAreaInlineSpacing});
      }
      :is(video, audio):not(.vjs-tech) {
        ${mediaMin.lg`
          height: 540px;
        `}
        ${mediaMin.xl`
          height: 624.375px;
        `}
      }
    }
    &:not(.big) {
      ${mediaMin.sm`
        margin-top: 0.5em;
        :is(video, audio):not(.vjs-tech) {
          height: 24.5vw;
        }
      `}

      :is(video, audio):not(.vjs-tech) {
        ${mediaMin.lg`
          height: 221px;
        `}
        ${mediaMin.xl`
          height: 256px;
        `}
      }
    }
  }
  .image-gallery {
    .cover {
      .text-overlay {
        .headline {
          font-size: 2.6rem;
          ${mediaMin.md`
            font-size: 2.8rem;
          `}
          ${mediaMin.lg`
            font-size: 3.2rem;
          `}
          ${mediaMin.xl`
          font-size: 3.6rem;
          `}

        }
      }
    }
    .text-overlay {
      .headline {
        ${textCssVariables.pangeaOpticalSize}: 20;
        margin: 0;
      }
    }

    ${mediaMin.xl`
      .control-bar-wrapper {
        max-width: 100%;
      }
      .text-overlay :is(.headline-container, .headline-wrapper, .description) {
        width: 100%;
      }
    `}
  }

  .simplebox {
    margin-block: 0.5em 1em;

    &:is(.left, .right) *:is(ol, ul) {
      --list-inline-start-spacing: 32px;
    }

    ${infoboxStylesString}
    ${infoboxLastChildNoMarginBottomStylesString}
  }
`;

export const socialMediaTextEmbeddingsCss = css`
  .yt-wrapper iframe {
    aspect-ratio: 16 / 9;
  }

  blockquote.fb-post,
  blockquote.fb-video,
  .instagram-oembed {
    display: flex !important;
    justify-content: center;
  }

  .instagram-oembed {
    overflow: auto;
  }

  ${mediaMax.xs`
    blockquote.fb-post,
    blockquote.fb-video {
      & span {
        max-width: 100%;
      }
    }
    blockquote.fb-post,
    blockquote.fb-video,
    .tweet {
      & iframe {
        max-width: 100%;
      }
    }
  `}
`;

export const StyledContentDetailRichText = extendWithClassNames(ContentDetailRichText)(
  contentDetailRichTextCss,
  socialMediaTextEmbeddingsCss,
);

StyledContentDetailRichText.darkStyles = cx(
  TextDefault.darkStyles,
  StyledLinkBase.darkStyles,
  ImageBasicCaption.darkStyles,
  StyledInfobox.darkStyles,
  css`
    ${cssVariables.linkUnderlineColor}: ${colors.BLUE_GREY_03};
    ${imageCssVariables.background}: ${colors.BLUE_GREY_04};
  `,
);
