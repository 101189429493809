import { styled } from '@linaria/react';
import gql from 'graphql-tag';

import { mediaMin } from '../../utils/css';
import { makeMemoFragment } from '../../utils/graphql';
import { HeadlineSemiBoldBlueGrey04AndBlueGrey03 } from '../Text/Headline.jsx';
import { withTheme } from '../higherOrderComponents/withTheme.jsx';

export const coCoPageHeadlineInHeaderFragment = makeMemoFragment({
  name: 'CoCoPageHeadlineInHeader',
  fragment() {
    return gql`fragment ${this.name} on CoCoAspect {
      pageHeadline
    }
    `;
  },
});

export const CoCoPageHeadlineInHeader = ({ navigation, className }) => (
  <div className={className}>
    <HeadlineSemiBoldBlueGrey04AndBlueGrey03 className="content-title">
      {navigation.pageHeadline}
    </HeadlineSemiBoldBlueGrey04AndBlueGrey03>
  </div>
);
export const StyledCoCoPageHeadlineInHeader = styled(CoCoPageHeadlineInHeader)`
  width: 100%;
  display: flex;

  & > span {
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  margin-inline-end: 18px;
  font-size: 1.8rem;
  ${mediaMin.sm`
    font-size: 2rem;
  `}
  ${mediaMin.md`
    font-size: 2.4rem;
  `}
  ${mediaMin.lg`
    font-size: 2.6rem;
    margin-inline-end: 63px;
  `}
  ${mediaMin.xl`
    margin-inline-end: 181px;
  `}

`;

StyledCoCoPageHeadlineInHeader.darkStyles = HeadlineSemiBoldBlueGrey04AndBlueGrey03.darkStyles;

export const CoCoPageHeadlineInHeaderWithTheme = withTheme(StyledCoCoPageHeadlineInHeader)(StyledCoCoPageHeadlineInHeader.darkStyles);
