import { Helmet } from 'react-helmet-async';
import { useParams } from '../../dwouter';
import { StyledErrorMessage as ErrorMessage } from './ErrorMessage.jsx';
import { StyledLoadingMessage as LoadingMessage } from './LoadingMessage.jsx';

import { Header } from '../Header';
import { toIso639LangCode } from '../../utils/langMapper';
import { useSilentRedirectContext } from '../hooks/silentRedirectContext.jsx';

export const WithGraphQLHandling = ({
  gqlMeta, children,
}) => {
  const {
    error,
    loading,
    data = {},
    ...helpers
  } = gqlMeta;

  const { langCode } = useParams();
  const iso639LangCode = toIso639LangCode(langCode) || 'en';
  const { getIsSilent } = useSilentRedirectContext();

  const langCodeElem = (
    <Helmet>
      <html lang={iso639LangCode}/>
    </Helmet>
  );

  if (getIsSilent() && (loading || error)) {
    return null;
  }
  if (loading) {
    return (
      <>
        {langCodeElem}
        <Header/>
        <LoadingMessage/>
      </>
    );
  }

  if (error) {
    return (
      <>
        {langCodeElem}
        <Header/>
        <ErrorMessage error={error}/>
      </>
    );
  }
  return (
    <>
      {langCodeElem}
      {children(data, helpers)}
    </>
  );
};
