import { css, cx } from '@linaria/core';
import { StyledChevron as OpenCloseArrow } from '../../icons';
import { HeadlineSemiBoldDwDarkBlueAndWhite } from '../../Text/Headline.jsx';
import { mediaMin } from '../../../utils/css';
import { contentAreaPaddingStyles } from '../../layouts/ContentMoveUpLayout/ContentArea';
import { centeredContent } from '../../layouts/BasicLayout/index';
import { useTranslation } from '../../hooks/useTranslation.jsx';
import { metaBackground, pseudoAsBackground } from '../../../utils/gradientCss';
import { bgHeadline } from './TextOverlay.jsx';
import { usePageContext } from '../../PageEmbeddingContext.jsx';
import { ButtonWithIconOnly } from '../../buttons/Button';
import { TextDefault } from '../../Text';

export const ExpandableTextOverlay = ({
  className, title, teaser, onTextOverlayToggle, isTextOverlayOpened, isCurrentIndex,
}) => {
  const pageContext = usePageContext();
  const isTextOverlayOpenedClass = isTextOverlayOpened ? 'opened' : 'closed';
  const buttonTitle = useTranslation(`component.slideshow.text_overlay_button.label.${isTextOverlayOpened ? 'close' : 'open'}`);

  return (
    <section className={cx(expandableTextOverlayClass, isTextOverlayOpenedClass, className)}>
      <div className='headline-background-container'>
        <div className='headline-container'>
          <HeadlineSemiBoldDwDarkBlueAndWhite className={cx('headline', HeadlineSemiBoldDwDarkBlueAndWhite.darkStyles)} isA={ pageContext.isInRichText ? 'h3' : 'h2'}>{title}</HeadlineSemiBoldDwDarkBlueAndWhite>
          <ButtonWithIconOnly
            onClick={onTextOverlayToggle}
            title={buttonTitle}
            tabIndex={!isCurrentIndex ? -1 : 0}
            aria-expanded={isTextOverlayOpened}
            className={cx('arrow', ButtonWithIconOnly.darkStyles)}
            data-tracking-name="slide-show-overlay-text-arrow"
          >
            <OpenCloseArrow/>
          </ButtonWithIconOnly>
        </div>
      </div>
      <div className='description-container'>
        <TextDefault isA="p" className={cx('description', TextDefault.darkStyles)}>{teaser}</TextDefault>
      </div>
    </section>
  );
};

export const expandableTextOverlayClass = css`
  will-change: transform;
  overflow-anchor: none;

  &.opened {
    .arrow svg {
      transform: rotate(0deg);
      transition: transform 450ms cubic-bezier(0.43, 0, 0.09, 1);
    }
  }

  &.closed {
    .description-container {
      max-height: 0;
    }
    .description {
      margin-top: 12px;
      transition-property: margin-top;
      transition-delay: 450ms;
    }
  }

  .headline-background-container, .description-container {
    & > * {
      margin: 0;
      ${contentAreaPaddingStyles}
    }
  }

  .headline-background-container, .description-container {
    ${pseudoAsBackground}
  }

  .headline-background-container:before {
    ${bgHeadline}
  }

  .description-container:before {
    height: calc(100% + 15px);
    ${metaBackground}
  }

  .description-container {
    max-height: 32vw;
    transition: max-height 450ms cubic-bezier(0.43, 0, 0.09, 1);
  }

  .headline-background-container {
    .headline-container {
      margin-top: 10px;
      display: flex;
      padding-top: 47px;
      padding-bottom: 10px;
    }
  }

  .headline {
    font-size: 1.6rem;
    flex-grow: 1;
    max-width: calc(100% - 40px);
    margin-bottom: 0;
  }

  .arrow {
    margin-block: -10px;
    align-self: flex-start;
    svg {
      padding: 7.5px;
      transform: rotate(180deg);
      transition: transform 450ms cubic-bezier(0.43, 0, 0.09, 1);
    }
  }

  .description {
    max-height: 32vw;
    padding-bottom: 5px;
    font-size: 1.2rem;
    overflow-y: scroll;

    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      width: 0;
    }
  }

  ${mediaMin.sm`
    .headline-background-container {
      .headline-container {
        padding-top: 60px;
      }
    }
  `}

  ${mediaMin.md`
    .headline-background-container {
      .headline-container {
        padding-top: 70px;
      }
    }
  `}

  ${mediaMin.lg`
    .headline-background-container {
      .headline-container {
        padding-top: 85px;
      }
    }
  `}

  ${mediaMin.xl`
    .headline-background-container, .description-container {
      & > * {
        ${centeredContent}
      }
    }
  `}
`;
