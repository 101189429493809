import { styled } from '@linaria/react';
import gql from 'graphql-tag';
import { cx } from '@linaria/core';
import { colors } from '../../utils/css';
import { commonBlockSpacing } from '../ContentBlocks/ContentBlock';
import { useMobileAppContext } from '../hooks/mobileAppContext';
import { StyledFooterSectionMain as FooterSectionMain, footerSectionMainFragment } from './FooterSectionMain.jsx';
import { StyledFooterSectionStandard as FooterSectionStandard, footerSectionStandardFragment } from './FooterSectionStandard.jsx';
import { StyledFooterSectionSocialMedia as FooterSectionSocialMedia, footerSectionSocialMediaFragment } from './FooterSectionSocialMedia.jsx';
import { makeMemoFragment } from '../../utils/graphql';
import { ids } from '../../utils/staticElementIds';
import { darkChildTextColorOnPrint, transparentOnPrint } from '../GlobalPrintStyles';

export const footerFragmentFactory = ({ isContent = true } = {}) => makeMemoFragment({
  name: `Footer${isContent ? '' : 'OnQuery'}`,
  fragment() {
    return gql`fragment ${this.name} on ${isContent ? 'FooterAspect' : 'Query'} {
      footer${isContent ? '' : '(lang: $lang)'} {
        ...${footerSectionMainFragment.name}
        ...${footerSectionSocialMediaFragment.name}
        ...${footerSectionStandardFragment.name}
      }
    }
    ${footerSectionMainFragment.fragment()}
    ${footerSectionSocialMediaFragment.fragment()}
    ${footerSectionStandardFragment.fragment()}
  `;
  },
});

export const Footer = ({ className, content }) => {
  const { isMobileMode } = useMobileAppContext();

  if (isMobileMode) {
    return null;
  }

  return (
    <footer className={cx(transparentOnPrint, darkChildTextColorOnPrint, className)} id={ids.quickNav.footer}>
      <FooterSectionMain className={commonBlockSpacing} footer={content.footer}/>
      <FooterSectionSocialMedia className={commonBlockSpacing} footer={content.footer}/>
      <FooterSectionStandard className={commonBlockSpacing} footer={content.footer}/>
    </footer>
  );
};

export const StyledFooter = styled(Footer)`
  background-color: ${colors.DARK_BLUE_GREY_01};
  padding-top: 30px;
  padding-bottom: 60px;
`;
