import { Helmet } from 'react-helmet-async';

import { useParams } from '../dwouter';

import { Header } from '../components/Header';
import { NotFoundMessage } from '../components/NotFoundMessage.jsx';
import { StyledFooter as Footer } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { HttpStatus } from '../components/HttpStatus.jsx';
import { noRealContentLangBaseFn, PageWithData } from './PageWithData.jsx';

import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';

export const NotFoundPageNoQuery = ({
  className, topStoriesNavigation, topStoriesNavigations, footer,
}) => (
  <>
    <QuickNavigationList content={{ topStoriesNavigation }}/>
    <Header content={{ topStoriesNavigation, topStoriesNavigations }}/>
    <div className={className}>
      <BrowserOnlyBreakingBanner />
      <NotFoundMessage topStoriesNavigation={topStoriesNavigation}/>
    </div>
    <Footer content={{ footer }}/>
  </>
);

export const NotFoundPageNoQueryWithTheme = withTheme(NotFoundPageNoQuery)(NotFoundMessage.darkStyles);

export const NotFoundPage = () => {
  const { langCode = 'en' } = useParams();
  const queryDef = {
    path: `${langCode}/not-found`,
    depts: [langCode],
  };

  return (
    <HttpStatus code={404}>
      <PageWithData queryDef={queryDef} langBaseContentFn={noRealContentLangBaseFn}>
        {data => (
          <>
            <Helmet>
              <meta property="og:site_name" content="dw.com" />
            </Helmet>
            <NotFoundPageNoQueryWithTheme {...data}/>
          </>
        )}
      </PageWithData>
    </HttpStatus>
  );
};
