import gql from 'graphql-tag';
import { makeMemoFragment } from '../utils/graphql';
import { format, replaceFormatInUrl } from '../utils/imgUtils';
import { contentDetailKickerFragment } from './ContentDetailHeader/ContentDetailKicker.jsx';
import { StyledAVPlayerHero as VideoPlayerHero } from './AVPlayerHero.jsx';
import { useC2paUrlIfEnabled } from './hooks/c2paContext/useC2paUrlIfEnabled';

export const livestreamContentHeroFragment = makeMemoFragment({
  name: 'LivestreamContentHero',
  fragment() {
    return gql`fragment ${this.name} on Content {
          ... on NamedAspect {
            title
          }
          ... on AssociationsAspect {
            mainContentImageLink {
              target {
                ...on Image {
                  staticUrl
                }
              }
            }
            livestreams {
              id
              hlsVideoSrc: url
              name
              trackingId
              trackingCategories
              trackingContentType
              trackingDate
            }
          }
          ...${contentDetailKickerFragment.name}
        }
        ${contentDetailKickerFragment.fragment()}
    `;
  },
});

export const LivestreamContentHero = ({ children, content, className }) => {
  const livestream = content.livestreams[0];

  const posterImageUrlUnsigned = content.mainContentImageLink ? replaceFormatInUrl(content.mainContentImageLink?.target?.staticUrl, format['60X'].formats[5]) : '';
  const posterImageUrl = useC2paUrlIfEnabled({ imgUrl: posterImageUrlUnsigned });

  const fakeLivestream = {
    ...livestream,
    title: content.title,
    trackingLanguageCode: content.trackingLanguageCode,
    namedUrl: content.namedUrl,
    thematicFocusCategory: content.thematicFocusCategory,
    firstRegionArray: content.firstRegionArray,
    posterImageUrl,
    priority: content.priority,
    isLiveVideo: content.isLiveVideo,
  };

  return (
    <>
      <VideoPlayerHero className={className}
        content={fakeLivestream}
      />
      {children}
    </>
  );
};
