import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';
import {
  StyledListDefaultUnifiedHeadline as ListDefaultUnifiedHeadlineTemplate,
  listDefaultUnifiedHeadlineFragment,
} from '../../ContentTeaserSnippets/Templates/ListDefaultUnifiedHeadline.jsx';
import { StyledLinkWithArrow as LinkWithArrow, linkWithArrowFragment } from '../LinkWithArrow.jsx';
import { mainTopicFragment, MainTopic } from './MainTopic.jsx';
import { noop } from '../../../utils/commons';
import { isValidList } from '../../../utils/contentUtils';
import { makeMemoFragment } from '../../../utils/graphql';
import { DEFAULT_TEASER_SPACING } from '../../ContentTeaserSnippets/TeaserComposition';

const VISIBLE_TEASER_AMOUNT = 3;

export const topicClusterFragment = makeMemoFragment({
  name: 'TopicCluster',
  fragment() {
    return gql`fragment ${this.name} on Content {
      ... on AssociationsAspect {
        allDossierContents: dossierContents {
          ... on ModelAspect {
            id
          }
        }
        dossierContents(amount: ${VISIBLE_TEASER_AMOUNT}) {
          ...${listDefaultUnifiedHeadlineFragment.name}
        }
      }
      ...on TextualAspect {
          shortTeaser
          shortTitle
      }
      ...${linkWithArrowFragment.name}
      ...${mainTopicFragment.name}
    }
    ${listDefaultUnifiedHeadlineFragment.fragment()}
    ${linkWithArrowFragment.fragment()}
    ${mainTopicFragment.fragment()}
    `;
  },
});

const makeBottomLinkFn = content => () => ((
  <LinkWithArrow
    content={content}
    linkTranslation={{
      key: 'content_block.topic_cluster.all',
      parameters: { totalNumberOfStories: content.allDossierContents.length },
    }} />
));

export const TopicCluster = ({
  contents = [],
  className,
  pageSectionId = 'topic-cluster',
  headlineTranslation = 'content_block.topic_cluster.headline',
}) => {
  const [content] = contents;

  if (!isValidList(content?.dossierContents)) {
    return null;
  }

  return (
    <ListDefaultUnifiedHeadlineTemplate
      className={cx(className, topicClusterClass)}
      bottomElementFn={content?.allDossierContents?.length > VISIBLE_TEASER_AMOUNT ? makeBottomLinkFn(content) : noop }
      headlineTranslation={headlineTranslation}
      pageSectionId={pageSectionId}
      contents={content.dossierContents}
      preListSlotFn={() => (
        <MainTopic
          className="topic"
          content={content}
          topicTitleSelector={c => c.shortTitle}
          topicTeaserSelector={c => c.shortTeaser}
        />
      )}
    />
  );
};

export const topicClusterClass = css`
  .topic {
    margin-bottom: ${DEFAULT_TEASER_SPACING};
  }
`;

TopicCluster.darkStyles = MainTopic.darkStyles;
