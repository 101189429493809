import { InView } from 'react-intersection-observer';
import { cx } from '@linaria/core';
import { useParams, Redirect, useLocation } from '../dwouter';
import { HeaderWithTheme } from '../components/Header';
import { CoCoPageHeadlineInHeaderWithTheme } from '../components/CoCo/CoCoPageHeadlineInHeader.jsx';
import { StyledFooter as Footer } from '../components/Footer';
import { StyledQuickNavigationList as QuickNavigationList } from '../components/a11y/QuickNavigationList.jsx';
import { BrowserOnlyBreakingBanner } from '../components/BreakingBanner';
import { PageHeadline } from '../components/PageHeadline.jsx';
import { StyledA11yLinkTargetMarker as A11yLinkTargetMarker } from '../components/a11y/A11yLinkTargetMarker.jsx';
import { ids } from '../utils/staticElementIds';

import { PageWithData } from './PageWithData.jsx';
import { NotFoundPage } from './NotFoundPage.jsx';
import { PageHeaderMetadata } from '../components/PageHeaderMetadata.jsx';
import { findPathnameInAlternateUrls, isPathMatching } from '../components/commons/PathAnalyser';
import { HeadlinesList } from '../components/ContentBlocks/HeadlinesList';

import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { useLocationQueryParam } from '../components/hooks/useLocationQueryParam';
import { objectToQueryParams } from '../utils/url';
import { useHeaderColorToggle } from '../components/hooks/useHeaderColorToggle';
import { useTopOffsetHeaderThreshold } from '../components/hooks/useTopOffsetHeaderThreshold';

export const HeadlinesOverviewPageNoQuery = ({ content, className }) => {
  const { onChangeInViewListenerForHeaderSwitch, forcedOffsetParentForHeaderToggleClassName } = useHeaderColorToggle();
  const topOffsetHeaderThreshold = useTopOffsetHeaderThreshold();

  return (
    <>
      <PageHeaderMetadata content={content}/>
      <QuickNavigationList content={content}/>
      <HeaderWithTheme content={content}>
        {({ isHeaderInAlternateState }) => (isHeaderInAlternateState
          ? (
            <CoCoPageHeadlineInHeaderWithTheme navigation={content} />
          )
          : null
        )}
      </HeaderWithTheme>
      <div className={className}>
        <BrowserOnlyBreakingBanner />
        <A11yLinkTargetMarker id={ids.quickNav.mainContent} className={forcedOffsetParentForHeaderToggleClassName}>
          <InView as="div" onChange={onChangeInViewListenerForHeaderSwitch} rootMargin={topOffsetHeaderThreshold}>
            <PageHeadline>{content.pageHeadline}</PageHeadline>
          </InView>
          <HeadlinesList contents={content.last7DaysContents}
            headlineTranslation="content_block.headlines_list.headline"
            pageSectionId="headlines-list"
          />
        </A11yLinkTargetMarker>
      </div>
      <Footer content={content}/>
    </>
  );
};

HeadlinesOverviewPageNoQuery.darkStyles = cx(
  PageHeadline.darkStyles,
  HeadlinesList.darkStyles,
);

export const HeadlinesOverviewPageNoQueryWithTheme = withTheme(HeadlinesOverviewPageNoQuery)(HeadlinesOverviewPageNoQuery.darkStyles);

export const HeadlinesOverviewPage = () => {
  const { langCode } = useParams();
  const shortParam = useLocationQueryParam('short');
  const amount = shortParam === '1' ? 21 : undefined;
  const searchParams = objectToQueryParams(amount ? { amount } : {});

  const queryDef = {
    path: `${langCode}/headlines-overview?${searchParams}`,
    depts: [langCode, amount],
  };
  const [{ pathname, search }] = useLocation();

  return (
    <PageWithData queryDef={queryDef} langBaseContentFn={data => data.headlinesOverview}>
      {({ headlinesOverview }) => {
        if (!headlinesOverview) {
          return (
            <NotFoundPage />
          );
        }

        const { namedUrl } = headlinesOverview;
        const isFoundInAlternateUrls = findPathnameInAlternateUrls({ pathname, alternateUrls: headlinesOverview.alternateUrls });

        if (!isPathMatching({ namedUrl, pathname }) && !isFoundInAlternateUrls) {
          return <Redirect to={{ pathname: namedUrl, search }} />;
        }

        return (
          <HeadlinesOverviewPageNoQueryWithTheme content={headlinesOverview} />
        );
      }}
    </PageWithData>
  );
};
