import { suspendedComponentFactory } from '../../../SuspendedComponent/suspendedComponentFactory.jsx';
import { C2PA_QUERY_PARAM_NAME } from '../useC2paUrlIfEnabled';
import { BrowserOnly } from '../../../BrowserOnly.jsx';
import { useLocationQueryParam } from '../../useLocationQueryParam';

export const { C2paPin: LazyC2paPin } = suspendedComponentFactory(() => import('../C2paPin.jsx'));

export const MaybeC2paPin = props => {
  const isC2paEnabled = useLocationQueryParam(C2PA_QUERY_PARAM_NAME) === '1';

  if (!isC2paEnabled) {
    return null;
  }

  return (
    <BrowserOnly>
      <LazyC2paPin {...props} />
    </BrowserOnly>
  );
};
