import { styled } from '@linaria/react';
import { cx } from '@linaria/core';

import gql from 'graphql-tag';
import { colors, mediaMin } from '../utils/css';
import { centeredContent } from './layouts/BasicLayout';
import { contentAreaPaddingStyles } from './layouts/ContentMoveUpLayout/ContentArea';
import { StyledHiddenHeadline as HiddenHeadline } from './HiddenHeadline.jsx';
import { StyledVideo as Video } from './Video';
import { darkObservedContentDetailActionStyles, ObservedContentDetailActions, observedContentDetailActionsFragment } from './ObservedContentDetailActions.jsx';
import { useTranslation } from './hooks/useTranslation.jsx';
import { StyledMediaTitle as MediaTitle } from './MediaTitle.jsx';
import { StyledKickerText as KickerText, darkKickerTextStyles } from './ContentDetailHeader/KickerText.jsx';
import {
  livestreamScheduleFragment, livestreamScheduleTodayFragment, StyledLivestreamSchedule as LivestreamSchedule,
} from './ContentBlocks/LivestreamSchedule';
import { RunningLivestreamContextProvider, useTimeSlots } from './RunningLivestreamContext.jsx';
import { makeMemoFragment } from '../utils/graphql';
import { format } from '../utils/imgUtils';
import { getImageUrlReplacementFunction } from './HeroImage/utils';
import { useC2paUrlIfEnabled } from './hooks/c2paContext/useC2paUrlIfEnabled';

export const livestreamActionsFragment = observedContentDetailActionsFragment;

export const livestreamDetailsFragment = makeMemoFragment({
  name: 'LivestreamDetails',
  fragment() {
    return gql`fragment ${this.name} on Channel {
        name
        mainContentImageLink {
          target {
            ... on Image {
              staticUrl
            }
          }
        }
        ...${livestreamScheduleFragment.name}
      }
      ${livestreamScheduleFragment.fragment()}
    `;
  },
});

export const livestreamDetailsTodayFragment = livestreamScheduleTodayFragment;

const headlineAndKickerTranslation = channel => (
  {
    key: 'pages.tv_livestream.page_title',
    parameters: {
      channel: channel.name,
    },
  }
);

const imgReplacementFn = getImageUrlReplacementFunction('webp');
const getMainContentImageUrl = channel => {
  const staticUrl = channel.mainContentImageLink?.target?.staticUrl;
  if (!staticUrl) {
    return '';
  }
  return imgReplacementFn(staticUrl, format['60X'].formats[5]);
};
const getTitle = timeSlot => {
  if (timeSlot?.program?.name || timeSlot?.programElement?.name) {
    return [timeSlot?.program?.name, timeSlot?.programElement?.name].filter(Boolean).join(' — ');
  }
  return undefined;
};
export const LivestreamHero = ({
  channel,
  className,
  children,
}) => {
  const [currentSlot] = useTimeSlots();
  const title = getTitle(currentSlot);
  const ariaLabel = `${useTranslation('component.video_player.title_prefix.live_stream')} — ${title}`;
  const kickerText = useTranslation(headlineAndKickerTranslation(channel));
  const posterImageUrlUnsigned = currentSlot?.program?.posterImageUrl || getMainContentImageUrl(channel);
  const posterImageUrl = useC2paUrlIfEnabled({ imgUrl: posterImageUrlUnsigned });

  return (
    <>
      <div className={className}>
        <div className="video-wrapper">
          <KickerText className={cx('kicker', darkKickerTextStyles)}>
            {kickerText}
          </KickerText>
          {title && (
            <MediaTitle
              className="title"
              aria-label={ariaLabel}
              contentType={channel.__typename}
              title={title}
            />)
          }
          <Video
            className="video-player"
            content={{ ...channel, posterImageUrl }}
          />
          <ObservedContentDetailActions
            className={cx('actions', darkObservedContentDetailActionStyles)}
            content={channel}
          />
        </div>
      </div>
      {children}
    </>
  );
};

export const StyledLivestreamHero = styled(LivestreamHero)`
  background-color: ${colors.DARK_BLUE_GREY_02};

  .video-wrapper {
    display: flex;
    flex-direction: column;
    background-color: ${colors.BLACK};
  }

  .video-player {
    order: 1;
  }

  .kicker {
    order: 2;
    padding-top: 20px;
    padding-bottom: 12px;
  }

  .title {
    order: 3;
    padding-bottom: 20px;
  }

  .actions {
    order: 4;

    a,.icon {
      width: 40px;
    }
    .more-icon svg {
      width: 25px;
    }
  }

  .kicker, .title, .actions {
    ${contentAreaPaddingStyles}
  }

   ${mediaMin.md`
    .actions {
      margin: 20px 0;
    }
  `}

  ${mediaMin.xl`
    .video-wrapper {
      ${centeredContent}
    }
  `}
`;

export const LivestreamDetails = ({
  channel, today, className,
}) => (
  <RunningLivestreamContextProvider
    livestreamChannel={channel}
    today={today}
  >
    <HiddenHeadline isA="h1" translation={headlineAndKickerTranslation(channel)} />
    <article className={className}>
      <StyledLivestreamHero
        channel={channel}
      />
      <LivestreamSchedule />
    </article>
  </RunningLivestreamContextProvider>
);

export const StyledLivestreamDetails = LivestreamDetails;
