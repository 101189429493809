import { isDWLangCode } from '../../utils/langMapper';

const specialLanguages = ['fa-ir', 'fa-af', 'pt-br'];

export const toDwLangCode = inputLangCode => {
  if (!inputLangCode) {
    return 'en';
  }
  const normalizedLangCode = inputLangCode.toLowerCase();
  if (specialLanguages.includes(normalizedLangCode)) { // cant touch this ... tyryryry
    return normalizedLangCode;
  }
  if (normalizedLangCode.startsWith('pt')) {
    return 'pt-002';
  }
  const languagePart = normalizedLangCode.split('-')[0];
  if (!isDWLangCode(languagePart)) {
    return 'en';
  }
  return languagePart;
};
