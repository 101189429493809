import { cx } from '@linaria/core';

import { Header } from '../components/Header';
import { ContentPageLayout } from '../components/layouts/ContentPageLayout.jsx';
import { StyledFooter as Footer } from '../components/Footer';

import { OnDetailPageContext } from '../components/PageEmbeddingContext.jsx';
import { PageHeaderMetadata } from '../components/PageHeaderMetadata.jsx';
import { ImageSharingMetadata } from '../components/ImageSharingMetadata.jsx';
import { JsonLdScript } from '../components/JsonLdScript.jsx';

import { StyledLiveblogPostSharingDetails as LiveblogPostSharingDetails, darkLiveblogPostSharingDetailsStyles } from '../components/LiveblogPostSharingDetails';
import { WithGraphQLContentHandling } from '../components/commons/WithGraphQLContentHandling';
import { withTheme } from '../components/higherOrderComponents/withTheme.jsx';
import { useParams } from '../dwouter';

export const LiveblogPostSharingPageNoQuery = ({
  content,
}) => (
  <OnDetailPageContext>
    <PageHeaderMetadata content={content} />
    <ImageSharingMetadata content={content} />
    <JsonLdScript content={content}/>
    <Header content={content} />
    <ContentPageLayout
      DetailZoneFn={
        () => (
          <LiveblogPostSharingDetails
            content={content}
          />
        )
      }
    />
    <Footer content={content} />
  </OnDetailPageContext>
);

export const LiveblogPostSharingPageNoQueryWithTheme = withTheme(LiveblogPostSharingPageNoQuery)(
  cx(
    ContentPageLayout.darkStyles,
    darkLiveblogPostSharingDetailsStyles,
  ),
);

export const LiveblogPostSharingPage = () => {
  const { contentId, langCode } = useParams();
  const queryDef = {
    path: `${langCode}/content/liveblog-post/${contentId}`,
    depts: [langCode, contentId],
  };
  return (
    <WithGraphQLContentHandling queryDef={queryDef}>
      {({ content }) => (
        <LiveblogPostSharingPageNoQueryWithTheme
          content={content}
        />
      )}
    </WithGraphQLContentHandling>
  );
};
