// IMPORTANT: Avoid unnecessary groups in regex, always check in unit tests if all matches as expected
export const pathPartials = {
  langCodeRegex: '[a-z]{2}-?0?0?2?[briafhnt]{0,4}',
};

export const langCodeGroup = `(?<langCode>${pathPartials.langCodeRegex})`;

export const titleSlugGroup = '(?<title>.*)';
export const channelNameGroup = '(?<channel>english|spanish|arabic|russian)';
export const selectedTopicIdGroup = '(?<selectedTopicGroupId>.{1}|numbers|hashtag)';
export const contentIdGroup = '(?<contentId>\\d{1,9})';
export const contentTypeGroup = '(?<typeId>[a-z_]{1,15})';
export const pageNumberGroup = '(?<pageNumber>\\d{1,9})';

export const isPathMatching = ({ namedUrl, pathname }) => {
  if (namedUrl === pathname) {
    return true;
  }
  const pathSegments = pathname.split('/');
  if (pathSegments.at(-1).includes('page-')) {
    return namedUrl === pathSegments.slice(0, -1).join('/');
  }
  return false;
};

export const findPathnameInAlternateUrls = ({ pathname, alternateUrls }) => alternateUrls.find(url => pathname === url);
