import gql from 'graphql-tag';
import { css, cx } from '@linaria/core';
import { mediaMin } from '../../utils/css';
import { SingleAuthor, singleAuthorFragment } from '../SingleAuthor.jsx';
import { isValidList } from '../../utils/contentUtils';
import { makeMemoFragment } from '../../utils/graphql';

export const authorsInfoFragment = makeMemoFragment({
  name: 'AuthorInfo',
  fragment() {
    return gql`fragment ${this.name} on AssociationsAspect {
        persons {
          id
          ...${singleAuthorFragment.name}
        }
      }
      ${singleAuthorFragment.fragment()}
    `;
  },
});

export const AuthorsInfo = ({ persons = [], className }) => {
  const colClassNames = persons.length === 1 ? 'col-xl-6 one-author' : 'col-sm-6';

  if (!isValidList(persons)) {
    return null;
  }
  return (
    <div className={cx(className, 'row', authorsInfoClass)}>
      {persons.map(person => (
        <SingleAuthor
          className={cx('col col-12', colClassNames, 'author')}
          key={person.id}
          person={person}
        />
      ))}
    </div>
  );
};

AuthorsInfo.darkStyles = SingleAuthor.darkStyles;

export const authorsInfoClass = css`
  margin-top: 15px;

  .author:last-child {
    padding-bottom: 0;
  }

  ${mediaMin.sm`
    .one-author {
      max-width: 510px;
    }
  `}

  ${mediaMin.md`
    .one-author {
      max-width: 570px;
    }
  `}

  ${mediaMin.lg`
    .one-author {
      max-width: 530px;
    }
  `}
`;
