import { suspendedComponentFactory } from '../../SuspendedComponent/suspendedComponentFactory.jsx';
import { BrowserOnly } from '../../BrowserOnly.jsx';
import { C2PA_QUERY_PARAM_NAME } from './useC2paUrlIfEnabled';
import { useLocationQueryParam } from '../useLocationQueryParam';

// @VisibleForTesting
export const { C2paLayer: LazyC2paLayer } = suspendedComponentFactory(
  () => import('../../InfoLayer/C2paLayer.jsx'),
  { fallback: null },
);
export const { C2paDwContextProvider: LazyC2paDwContextProvider } = suspendedComponentFactory(
  () => import('./C2paDwContextProvider.jsx'),
  { fallback: null },
);
export const MaybeC2pa = ({ children }) => {
  const isC2paEnabled = useLocationQueryParam(C2PA_QUERY_PARAM_NAME) === '1';

  if (!isC2paEnabled) {
    return children;
  }

  return (
    <BrowserOnly>
      <LazyC2paDwContextProvider>
        {children}
        <LazyC2paLayer />
      </LazyC2paDwContextProvider>
    </BrowserOnly>
  );
};
